import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    findByProcessInjectionIdListReactive,
    processInjectionsListReactive,
    updateProcessInjectionsGridColumnModeReactive,
    updateProcessInjectionsGridColumnStateReactive,
    updateProcessInjectionsGridFilterModelReactive,
    updateProcessInjectionsGridUseColumnStateReactive,
    updateProcessInjectionsGridUseFilterStateReactive, updateShowAllInjectionsTelemetryReactive
} from "../api/processInjectionsApi";
import SidebarMenu from "../../components/sideBarComponent";
import {defaultZenGroupColumnInitWithOptionsWithValueGetter} from "../../utils/zenGroupDisplayNameGridHelper";
import {defaultAgentNameColumnInitWithOptions} from "../../utils/agentNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {base64DecodedValueFormatter} from "../../utils/gridBase64ValueFormatter";
import {agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped,} from "../../utils/gridCellEditing";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {agentVersionFormatter} from "../../utils/agentVersionFormatter";
import {masterFilterHelper} from "../../utils/filterHelper";
import {
    getColumnModeInSession,
    getDefaultAgGridSidebarProps, getTelemetryDataVisibilitySettingInSession,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState, onShowAllTelemetryDataChangedHelper, showAllInjectionsTelemetryVariable,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {useLocation} from "react-router-dom";
import {findZenGroupById} from "../../utils/zenGroupSessionStorageManager";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";

let gridColumnStateSessionVariableName = "processInjectionsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "agentDisplayName", "injectorPath", "injecteePath", "timestamp"]
let medColumnIds = ["zenGroupDisplayName", "agentDisplayName", "injectorPath", "injectorArgs", "injecteePath", "injecteeArgs", "timestamp"]
let saveFilterChanges = true //used to help let ag grid code know to save filters or not, when this is false that means we were redirected from incidents page and don't want to save filters since we will be auto filtering
export default function ProcessInjections() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [zenGroups,setZenGroups] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [defaultZenGroup,setDefaultZenGroup] = useState(null);
    const [gridApi, setGridApi] = useState();
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("processInjectionsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [showAllTelemetryDataToggled, setShowAllTelemetryDataToggled] = useState(getTelemetryDataVisibilitySettingInSession(showAllInjectionsTelemetryVariable, "processInjectionsGridFilterState"));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const processInjectionLocation = useLocation();
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(false, true, true),
        defaultAgentNameColumnInitWithOptions(false),
        { field: "injectorPath", headerName: "Injector Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "injectorArgs", headerName: "Injector Arguments", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'startsWith'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "injecteePath", headerName: "Injectee Path", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "injecteeArgs", headerName: "Injectee Arguments", initialWidth: 500,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'startsWith'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "injectorPid", headerName: "Injector Pid", initialWidth: 200,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "injecteePid", headerName: "Injectee Pid", initialWidth: 200,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "timestamp", headerName: "Created", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateCollected", headerName: "Collected", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "injecteeUniqueProcessIdentifier", headerName: "Injectee Unique Process Identifier", initialWidth: 280,
            filter: 'agTextColumnFilter',
            hide: true,
            suppressColumnsToolPanel: true,
            lockVisible: true,
        },
        { field: "agentVersionFormatted", headerName: "Agent Version", initialWidth: 280,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            sortable: true,
            valueFormatter: agentVersionFormatter,
            cellRenderer: function(params){
                let spinnerDiv = ""
                if(params.node.data.agentId === params.valueFormatted ){
                    spinnerDiv = <FontAwesomeIcon
                        className="contain fa-pulse"
                        icon="fa-light fa-spinner"
                        size="lg"
                        name="agentVersionLoading"
                    />
                }else{
                    spinnerDiv = ""
                }
                return(
                    <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        {spinnerDiv}
                        {params.valueFormatted}
                    </div>
                )
            },


        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Process Injections</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col ml-5 mr-10 mt-8 flex-nowrap gap-y-2 h-full">
                    {privatePageHeaderHelper("Process Injections")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}
                                showAllTelemetryDataToggled={showAllTelemetryDataToggled}
                                toggleUpdateShowAllTelemetryData={toggleUpdateShowAllTelemetryData}
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateProcessInjectionsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi}
                                          refreshGridFunction = {refreshGrid}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'processInjectionsGridFilterState', updateProcessInjectionsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateProcessInjectionsGridUseColumnStateReactive);
    }
    function toggleUpdateShowAllTelemetryData(toggleSetting){
        //Make sure gridApi is initialized, else do nothing
        if(gridApi !== null && gridApi !== undefined){
            setShowAllTelemetryDataToggled(toggleSetting)
            onShowAllTelemetryDataChangedHelper(showAllInjectionsTelemetryVariable, toggleSetting, "processInjectionsGridFilterState", updateShowAllInjectionsTelemetryReactive)
            let dataSource = getDatasourceForGrid(toggleSetting)
            gridApi.setGridOption("serverSideDatasource", dataSource);
        }
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                setGridApi={setGridApi}
                setZenGroups={setZenGroups}
                setDefaultZenGroup={setDefaultZenGroup}
                processInjectionLocation={processInjectionLocation}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                showAllTelemetryDataToggled={showAllTelemetryDataToggled}
                setShowAllTelemetryDataToggled={setShowAllTelemetryDataToggled}
            />
        );
    }

    async function refreshGrid(){
        await refreshGridZenGroupAndAgentInformation(gridApi, "procInjectionId", "id", findByProcessInjectionIdListReactive, null, "agentId",null)
    }
    /*
    function resetGrid(){
        ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
    }

     */
}

//Standardizing datasource for injections grid since the telemetry toggle and initial onGridReady will need to set the datasource
function getDatasourceForGrid(localShowAllTelemetryToggled){
    return {
        getRows: async function (params) {
            try {
                let start = params.request.startRow;
                let end = params.request.endRow;

                let filters = masterFilterHelper(params.request.filterModel)
                let sortModel = params.request.sortModel;
                let sortModelForRequest = null;
                if (sortModel && sortModel.length > 0) {
                    try { //extra precaution for array access on the sort model so it does not break
                        if (sortModel[0].colId && sortModel[0].sort) {
                            sortModelForRequest = sortModel[0]
                        }
                    } catch (error) {
                        console.log("Error generating sort request")
                    }
                }
                let count = end - start;
                let page = Math.floor(start / count)
                let objects = await processInjectionsListReactive(count, page, filters, sortModelForRequest, localShowAllTelemetryToggled);
                let finalPage = true
                //This logic mimics how we would check in the rest api for what to send for the finalPage in the response. Same logic is being used here so we can just return a flux from the
                // endpoint instead of Mono with a Response object
                if (objects && objects.length === count) {
                    finalPage = false
                }
                let lastIdx = undefined
                if (finalPage) {
                    lastIdx = (count * page) + objects.length
                }
                params.api.deselectAll();
                const success = (objects.length !== 0);
                if (success) {
                    params.api.hideOverlay()
                    params.success({
                        rowData: objects, rowCount: lastIdx
                    })
                } else {
                    //params.api.showNoRowsOverlay();
                    params.success({
                        rowData: objects, rowCount: lastIdx
                    }) //to stop loading circle from spinning and make it disappear, nothing in objects.
                    params.fail();
                }

            } catch (error) {
                //console.log(error);
                params.api.showNoRowsOverlay();
                params.fail();
            }
        }
    }
}

class Grid extends Component {
    constructor(props, onClickRow, filterVals, setGridApi) {
        super(props);
    }
    onFirstDataRendered = (params) => {
    };

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateProcessInjectionsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateProcessInjectionsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateProcessInjectionsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateProcessInjectionsGridColumnModeReactive, customColumnModeText)
        }
    }
    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };
    onGridReady = async (params) => {
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
        //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
        if(this.props.processInjectionLocation && this.props.processInjectionLocation.state && this.props.processInjectionLocation.state.zenGroupIdClicked
            && this.props.processInjectionLocation.state.uniqueProcessIdentifier){
            let zenGroupId = this.props.processInjectionLocation.state.zenGroupIdClicked
            let injecteeUniqueProcessIdentifier = this.props.processInjectionLocation.state.uniqueProcessIdentifier
            let locationFilterModel = {"injecteeUniqueProcessIdentifier": {
                    filterType: "text",
                    type: "equals",
                    filter: JSON.stringify(injecteeUniqueProcessIdentifier) //need to convert to string for ag grid filters
            }}
            let zenGroup = findZenGroupById(zenGroupId)
            if(zenGroup && zenGroup.friendlyName){
                locationFilterModel["zenGroupDisplayName"] = {filterType: "set", values: [zenGroup.friendlyName]}
            }
            //we don't want to save filter changes for the user if we are coming from a page where they clicked the agent link
            saveFilterChanges = false
            params.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            window.scroll({behavior: "smooth", top: 0, left: 0})
            //remove from state so if they hit back tab then forward tab filter is not applied again
            window.history.replaceState(this.props.processInjectionLocation.state, '')
        } else {
            saveFilterChanges = true
            onGridReadyHelper(params, "processInjectionsGridFilterState");
        }

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateProcessInjectionsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateProcessInjectionsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        //this.gridOptions.api.setFilter
        this.gridApi = params.api;
        this.props.setGridApi(params.api);
        let showAllTelemetry = this.props.showAllTelemetryDataToggled
        if(!saveFilterChanges){ //if coming from root cause, always send false so query uses injections collection in prod db that does not age off
            showAllTelemetry = false
            //If coming from browser incidents page for root cause, we need show all telemetry true
            if(this.props.processInjectionLocation && this.props.processInjectionLocation.state && this.props.processInjectionLocation.state.browserAccessRootCause){
                showAllTelemetry = true
            }
            //also set the toggle to false. Don't save the setting though for user since we are manually setting to false. If they toggle again after this, then it will save
            this.props.setShowAllTelemetryDataToggled && this.props.setShowAllTelemetryDataToggled(showAllTelemetry)
        }
        let dataSource = getDatasourceForGrid(showAllTelemetry)
        params.api.setGridOption("serverSideDatasource", dataSource);

        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'processInjectionsGridFilterState', updateProcessInjectionsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
