import axios from "../../utils/axiosInstance";

export const listAllGroupsCCOnly = async () => {
    const response = await axios.post("/listAllGroupsCCOnly", {

    })
    return response.data
};

export const listAllUserGroupsCCOnly = async (username) => {
    const response = await axios.post("/listAllUserGroupsCCOnly", {
        username: username
    })
    return response.data
};
export const addSelfToGroupsCCOnly = async (groupIds) => {
    return await axios.post("/addSelfToGroupsCCOnly", {
        groupIds: groupIds
    })
};
export const removeSelfFromGroupsCCOnly = async (groupIds) => {
    return await axios.post("/removeSelfFromGroupsCCOnly", {
        groupIds: groupIds
    })
};
export const createLicensesCCOnly = async (zenGroupId, pocLicenses, numDesktopLicenses, numServerLicenses, expirationYear, expirationMonth, expirationDayOfMonth, startYear, startMonth, startDayOfMonth) => {
    return await axios.post("/createLicensesCCOnly", {
        zenGroupId: zenGroupId,
        pocLicenses: pocLicenses,
        numDesktopLicenses: numDesktopLicenses,
        numServerLicenses: numServerLicenses,
        expirationYear: expirationYear,
        expirationMonth: expirationMonth,
        expirationDayOfMonth: expirationDayOfMonth,
        startYear: startYear,
        startMonth: startMonth,
        startDayOfMonth: startDayOfMonth
    })
};