import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/dark.css'
import {decryptAndGetSessionVariable} from "./storageHelper";

export default forwardRef((props, ref) => {
    const [date, setDate] = useState(null);
    const [picker, setPicker] = useState(null);
    const refFlatPickr = useRef(null);
    const refInput = useRef();
    const dateRef = useRef();

    const onDateChanged = (selectedDates) => {
        const newDate = selectedDates[0];
        setDate(newDate);
        dateRef.current = newDate;
        props.onDateChanged();
    };

    useEffect(() => {
        setPicker(flatpickr(refFlatPickr.current, {
            onChange: onDateChanged,
            dateFormat: "F d, Y h:i:S K",
            wrap: true,
            enableTime: true,
            enableSeconds: true
        }));
    }, []);

    useEffect(() => {
        if (picker && picker.calendarContainer && picker.calendarContainer.classList) {
            picker.calendarContainer.classList.add('ag-custom-component-popup');
        }
    }, [picker]);

    useEffect(() => {
        if (picker) {
            refInput.current.setAttribute('placeholder', "yyyy-mm-dd");
        }
    }, [picker]);

    useEffect(() => {
        if (picker) {
            picker.setDate(date);
        }
    }, [date, picker]);

    useImperativeHandle(ref, () => ({
        getDate() {
            return dateRef.current;
        },

        setDate(date) {
            dateRef.current = date;
            setDate(date);
        },

        setInputPlaceholder(placeholder) {
            if (refInput.current) {
                refInput.current.setAttribute('placeholder', placeholder);
            }
        },

        setInputAriaLabel(label) {
            if (refInput.current) {
                refInput.current.setAttribute('aria-label', label);
            }
        }

    }));
    return (
        <div className="ag-input-wrapper custom-date-filter"  role="presentation" ref={refFlatPickr}>
            <input type="text" ref={refInput} data-input style={{ width: "100%" }} />
            <a className='input-button' title='clear' data-clear>
                <i className='fa fa-times'></i>
            </a>
        </div>
    );
});
export function dateFilterParametersInHeaderClientSideGrid () {
    return {
        defaultOption: "inRange",
        maxNumConditions: 2,
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange', 'blank', 'notBlank'],
        buttons: ["reset", "apply", "cancel"],
        comparator: function (filterLocalDate, cellValue) {
            return dateFilter(filterLocalDate, cellValue)
        }
    }
}
export function dateFilterParametersInHeaderSuppressSorting () {
    return {
        suppressSorting: true,
        
        defaultOption: "inRange",
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1,
        buttons: ["reset", "apply", "cancel"],
        comparator: function (filterLocalDate, cellValue) {
            return dateFilter(filterLocalDate, cellValue)
        }
    }
}
export function dateFilter (filterLocalDate, cellValue){

    let test = new Date()
    let timezone = decryptAndGetSessionVariable("timezone")
    if(timezone === null || timezone === undefined){
        timezone = "Europe/London" //default to london gmt time if timezone is null
    }
    let testSessionTime = (new Date(Date.parse(test.toLocaleString('en-US', {
        timeZone: timezone
    }))));

    let sessionTZOffset = Math.ceil((testSessionTime - test) / 36e5) * 60 * 60 * 1000;
    let browserTZOffset = filterLocalDate.getTimezoneOffset() * 60 * 1000

    cellValue = Math.floor((Date.parse(cellValue) - browserTZOffset)/1000)
    filterLocalDate = (filterLocalDate.getTime() - (sessionTZOffset) - browserTZOffset)/1000

    if (filterLocalDate === cellValue) {
        return 0;
    }

    if (cellValue < filterLocalDate) {
        return -1;
    }

    if (cellValue > filterLocalDate) {
        return 1;
    }
}
