import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener, standardHandleInsertEvent,
    standardHandlePopulateGrid, standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    editPartnerOrganizationLeadNotesCCOnly, editPartnerOrganizationLeadOwnerCCOnly,
    getPartnerOrgLeadOwnerUserOptionsCCOnly
} from "../api/partnerOrganizationLeadsApi";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Modal from "react-modal";
import {Autocomplete, Button, TextField, ThemeProvider} from "@mui/material";
import {autocompleteTheme, buttonTheme} from "../../utils/muiStyling";
import {launchLinkedInCompanyScraperPhantomBusterReactive} from "../api/ccOnlyLinkedInCompanyProfilesApi";
import {ccGetAllNonChannelPartnerGroupsReactive} from "../api/groupsApi";
import {checkPermission} from "../../utils/permissionCheckHelper";

export default function CCOnlyPartnerOrganizationLeads() {
    const [isLoading, setIsLoading] = useState(false);
    const [leadOwnerOptionsList, setLeadOwnerOptionsList] = useState([]);
    const [phantomBusterModalIsOpen, setPhantomBusterModalIsOpen] = useState(false);
    const [bulkPhantomBusterModalIsOpen, setBulkPhantomBusterModalIsOpen] = useState(false);
    const [enableButtons, setEnableButtons] = useState(false);
    const [linkedInSessionCookie, setLinkedInSessionCookie] = useState("");
    const [linkedInCompanyURL, setLinkedInCompanyURL] = useState("");
    const [modalOrgName, setModalOrgName] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "name", initialWidth: 300, headerName: "Organization Name",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        {
            field: "city", initialWidth: 210, headerName: "Organization City",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-location-dot" size="xs"/>}
                            tooltipTitle={`Full Address: ${params.node.data.fullAddress}`}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
        },
        {
            field: "state", initialWidth: 210, headerName: "Organization State",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-location-dot" size="xs"/>}
                            tooltipTitle={`Full Address: ${params.node.data.fullAddress}`}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
        },
        { field: "orgSize", headerName: "Organization Size Range", sortable: true, initialWidth: 250,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                let value = ""
                if(params.node.data.sizeLowerRange){
                    value += `${params.node.data.sizeLowerRange}`
                }
                if(params.node.data.sizeUpperRange){
                    value += `-${params.node.data.sizeUpperRange}`
                }
                return value
            },
        },
        { field: "dateCollected", headerName: "Date Collected",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        {
            field: "leadOwnerUsername", initialWidth: 350, headerName: "Lead Owner",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true,
            valueGetter: (params) => {
                if(params.node.data.leadOwnerUsername !== null && params.node.data.leadOwnerUsername !== undefined && params.node.data.leadOwnerUsername.trim().length > 0){
                    return params.node.data.leadOwnerUsername
                }
                else{
                    return "None"
                }
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    //Don't allow editing until the useEffect runs to populate the cell editor values
                                    if(params.column?.colDef?.cellEditorParams?.values && params.column?.colDef?.cellEditorParams?.values?.length > 0){
                                        params.api.startEditingCell({
                                            rowIndex: params.node.rowIndex,
                                            colKey: "leadOwnerUsername"
                                        })
                                    }
                                }
                            }}
                            enterDelayMillis={100}
                            tooltipTitle={<div>Click to assign the owner of this partner organization lead</div>}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: { values: leadOwnerOptionsList },
        },
        {
            field: "runAgent",
            headerName: "Run LinkedIn Company Scraper",
            sortable: false,
            editable: false,
            initialWidth: 300,
            cellRenderer: function (params) {
                if(params.node.data.linkedInOrganizationProfileURL && params.node.data.linkedInOrganizationProfileURL.trim().length > 0){
                    return (
                        <div className={`flex flex-row items-center gap-x-1`}>
                            <MuiIconButtonWithTooltip
                                icon={<LinkedInIcon className={"cursor-pointer"}/>}
                                onClick={() => {
                                    resetPhantomBusterModal()
                                    setModalOrgName(params.node.data.name)
                                    setLinkedInCompanyURL(params.node.data.linkedInOrganizationProfileURL)
                                    setPhantomBusterModalIsOpen(true)
                                }}
                                enterDelayMillis={1000}
                                tooltipTitle={<div>Click to run the LinkedIn Company Scraper for this organization</div>}
                                tooltipPlacement={"bottom-start"}
                            />
                            Run Company Scraper
                        </div>
                    )
                }
                else{
                    return null
                }
            }
        },
        { field: "dateLastCompanyProfileScraped", headerName: "Date Last Company Profile Scrape",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "linkedInOrganizationProfileURL", headerName: "LinkedIn Company Profile URL", sortable: true, initialWidth: 350,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            cellRenderer: function (params) {
                let tooltipDiv = ""
                if(params.value){
                    tooltipDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-up-right-from-square" size="xs"/>}
                        onClick={() => {
                            window.open(params.value, "_blank", "noreferrer")
                        }}
                        tooltipTitle={`Click to Open in New Tab`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        {tooltipDiv}
                        {params.value}
                    </div>
                )
            },
        },
        { field: "partnerOverviewURL", headerName: "Partner Overview URL", sortable: true, initialWidth: 350,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            cellRenderer: function (params) {
                let tooltipDiv = ""
                if(params.value){
                    tooltipDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-up-right-from-square" size="xs"/>}
                        onClick={() => {
                            window.open(params.value, "_blank", "noreferrer")
                        }}
                        tooltipTitle={`Click to Open in New Tab`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        {tooltipDiv}
                        {params.value}
                    </div>
                )
            },
        },
        { field: "notes", headerName: "Internal Notes", sortable: true, editable: true, initialWidth: 300,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 300
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        <MuiIconButtonWithTooltip
                            icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="xs"/>}
                            onClick={() => {
                                //manually start editing the cell
                                if(params.api && params.node){
                                    params.api.startEditingCell({
                                        rowIndex: params.node.rowIndex,
                                        colKey: "notes"
                                    })
                                }
                            }}
                            enterDelayMillis={100}
                            tooltipTitle={<div>Click to view/edit notes for this partner. <br /><br />Note that clicking the 'enter' key (or clicking away from text box) will
                                finish editing, hold the 'shift' key while pressing the 'enter' key to go to a new line. <br /> <br />To discard changes, click the escape key</div>}
                            tooltipPlacement={"bottom-start"}
                        />
                        {params.value}
                    </div>
                )
            },
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(325)
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(gridApi !== null && gridApi !== undefined){
                getPartnerOrgLeadOwnerUserOptionsCCOnly().then(response => {
                    response.sort((object1, object2) => (object1?.toLowerCase() > object2?.toLowerCase()) ? 1 : -1)
                    response.unshift("None") //Add option for None
                    setLeadOwnerOptionsList(response)
                    //Update column definition for leadOwnerUsername with new values since it won't get the hook list update
                    columnDefs?.forEach((col) => {
                        if(col && col.field === "leadOwnerUsername"){
                            col.cellEditorParams = {values: response}
                        }
                    })
                    gridApi.setGridOption("columnDefs", columnDefs);

                }).catch(error => {})
            }
        })()
        return () => controller?.abort();
    }, [gridApi]);

    function resetPhantomBusterModal(){
        setPhantomBusterModalIsOpen(false)
        setBulkPhantomBusterModalIsOpen(false)
        setLinkedInSessionCookie("")
        setLinkedInCompanyURL("")
        setModalOrgName("")
    }

    function submitPhantomBusterModal(){
        if(linkedInCompanyURL === null || linkedInCompanyURL === undefined || linkedInCompanyURL.trim().length <= 0){
            NotificationManager.info(`Unexpected error making request`);
            return
        }
        let cookieToSend = null
        if(linkedInSessionCookie !== null && linkedInSessionCookie !== undefined && linkedInSessionCookie.trim().length > 0){
            cookieToSend = linkedInSessionCookie
        }
        setIsLoading(true)
        launchLinkedInCompanyScraperPhantomBusterReactive([linkedInCompanyURL], cookieToSend).then(function(response){
            setIsLoading(false)
            NotificationManager.success("Successfully sent request");
            resetPhantomBusterModal()
        }).catch(function(error){
            setIsLoading(false)
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Unexpected error making request")
            }
        })

    }

    function submitBulkPhantomBusterModal(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let urlsToSend = []
            gridApi.getSelectedNodes().forEach(rowNode => {
                if(rowNode.data.linkedInOrganizationProfileURL && rowNode.data.linkedInOrganizationProfileURL.trim().length > 0){
                    urlsToSend.push(rowNode.data.linkedInOrganizationProfileURL.trim())
                }
            })
            if(urlsToSend.length < 1){
                NotificationManager.info(`No valid LinkedIn Company Profile URLs selected`);
                return
            }

            let cookieToSend = null
            if(linkedInSessionCookie !== null && linkedInSessionCookie !== undefined && linkedInSessionCookie.trim().length > 0){
                cookieToSend = linkedInSessionCookie
            }
            setIsLoading(true)
            launchLinkedInCompanyScraperPhantomBusterReactive(urlsToSend, cookieToSend).then(function(response){
                setIsLoading(false)
                NotificationManager.success("Successfully sent request");
                resetPhantomBusterModal()
            }).catch(function(error){
                setIsLoading(false)
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error making request")
                }
            })
        }
        else{
            NotificationManager.info(`Unexpected error making request`);
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Partner Organization Leads</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Run Phantom Buster LinkedIn Company Scraper"
                   isOpen={phantomBusterModalIsOpen}
                   onRequestClose={() => {
                       resetPhantomBusterModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Run Phantom Buster LinkedIn Company Scraper</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetPhantomBusterModal()
                            }}
                        />
                    </div>
                    <hr className="h-0.5"/>
                    {/*Form content*/}
                    <div className="flex flex-col gap-y-5">
                        <label><label className="font-bold">Organization Name: </label>{modalOrgName}</label>
                        <label className={"break-all"}><label className="font-bold">LinkedIn Company URL: </label>{linkedInCompanyURL}</label>
                    </div>
                    <hr className="h-0.5"/>
                    <div className="flex flex-col gap-y-5">
                        <label>We use a saved LinkedIn session cookie to run the Phantom Buster Agent, but the
                            cookie may expire. If a new cookie is needed, enter it below</label>
                    </div>
                    <TextField
                        size={"small"}
                        id="outlined-controlled"
                        label="Optional - LinkedIn Session Cookie"
                        value={linkedInSessionCookie}
                        onChange={(event) => {
                            setLinkedInSessionCookie(event.target.value);
                        }}
                    />
                    <div className="flex flex-col">
                        <ThemeProvider theme={buttonTheme}>
                            <Button type={"submit"} color={"primary"} variant={"contained"}
                                    onClick={() => {
                                        submitPhantomBusterModal()
                                    }}
                            >
                                Submit
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <Modal contentLabel="Bulk Run Phantom Buster LinkedIn Company Scraper"
                   isOpen={bulkPhantomBusterModalIsOpen}
                   onRequestClose={() => {
                       resetPhantomBusterModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Bulk Run Phantom Buster LinkedIn Company Scraper</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetPhantomBusterModal()
                            }}
                        />
                    </div>
                    <hr className="h-0.5"/>
                    {/*Form content*/}
                    <div className="flex flex-col gap-y-2">
                        <label>Each selected organization lead on the grid will be sent to run the Phantom Buster Agent.</label>
                        <label>Note that a max amount of 20 organization leads can be sent at a time to help with rate limiting and reaching
                            the max parallelism for Phantom Buster.</label>
                        <labeL>A small delay between each launch will be added as well to help with this</labeL>
                    </div>
                    <hr className="h-0.5"/>
                    <div className="flex flex-col gap-y-2">
                        <label>We use a saved LinkedIn session cookie to run the Phantom Buster Agent, but the
                            cookie may expire. If a new cookie is needed, enter it below</label>
                    </div>
                    <TextField
                        size={"small"}
                        id="outlined-controlled"
                        label="Optional - LinkedIn Session Cookie"
                        value={linkedInSessionCookie}
                        onChange={(event) => {
                            setLinkedInSessionCookie(event.target.value);
                        }}
                    />
                    <div className="flex flex-col">
                        <ThemeProvider theme={buttonTheme}>
                            <Button type={"submit"} color={"primary"} variant={"contained"}
                                    onClick={() => {
                                        submitBulkPhantomBusterModal()
                                    }}
                            >
                                Submit
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Partner Organization Leads")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            <MuiIconButtonWithTooltipAndBox
                                icon={<LinkedInIcon className={"cursor-pointer"}/>}
                                disabled={!enableButtons}
                                tooltipTitle={"Run Phantom Buster Agent for LinkedIn Company Profiles for Selected Organization Leads"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetPhantomBusterModal()
                                    setBulkPhantomBusterModalIsOpen(true)
                                }}/>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClearRefresh gridApi={gridApi} showRefreshIcon={false} showExcelExportIcon={true}
                                          sseDataPullActive={sseDataPullActive}
                                          excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            excelExport={excelExport}
                            setIsLoading={setIsLoading}
                            setEnableButtons={setEnableButtons}
                        />
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "partnerOrganizationLeadsGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
        if(event.column.colId === "notes"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            let id = event.data.id
            if(newValue === oldValue){
                event.data.notes = event.oldValue
                gridApi.refreshCells({columns: ["notes"], suppressFlash: true, rowNodes: [event.node], force: true})
                return;
            }
            if(!id){
                event.data.notes = event.oldValue
                gridApi.refreshCells({columns: ["notes"], suppressFlash: true, rowNodes: [event.node], force: true})
                return;
            }
            //this.props.setIsLoading && this.props.setIsLoading(true)
            editPartnerOrganizationLeadNotesCCOnly(id, event.newValue).then(result => {
                NotificationManager.success("Successfully updated notes")
                this.props.setIsLoading && this.props.setIsLoading(false)
            }).catch(error => {
                this.props.setIsLoading && this.props.setIsLoading(false)
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error updating this group")
                }
                event.data.notes = event.oldValue
                gridApi.refreshCells({columns: ["notes"], suppressFlash: true, rowNodes: [event.node], force: true})
            })


        }
        else if(event.column.colId === "leadOwnerUsername"){
            let newValue = event.newValue
            let oldValue = event.oldValue
            let id = event.data.id
            if(newValue === oldValue){
                event.data.leadOwnerUsername = event.oldValue
                gridApi.refreshCells({columns: ["leadOwnerUsername"], suppressFlash: true, rowNodes: [event.node], force: true})
                return;
            }
            if(!id){
                event.data.leadOwnerUsername = event.oldValue
                gridApi.refreshCells({columns: ["leadOwnerUsername"], suppressFlash: true, rowNodes: [event.node], force: true})
                return;
            }
            //If None is selected, then send null in request to signal there should be no lead owner
            if(newValue === "None"){
                newValue = null
            }
            //this.props.setIsLoading && this.props.setIsLoading(true)
            editPartnerOrganizationLeadOwnerCCOnly(id, newValue).then(result => {
                NotificationManager.success("Successfully updated lead owner")
                this.props.setIsLoading && this.props.setIsLoading(false)
            }).catch(error => {
                this.props.setIsLoading && this.props.setIsLoading(false)
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error updating this group")
                }
                event.data.leadOwnerUsername = event.oldValue
                gridApi.refreshCells({columns: ["leadOwnerUsername"], suppressFlash: true, rowNodes: [event.node], force: true})
            })
        }
    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Default sort to sort on dateCollected desc.
        if(gridReadyParams && gridReadyParams.api){
            gridReadyParams.api.applyColumnState({
                state: [{colId: "dateCollected", sort: "desc"}]
            })
        }

        await loadDataWithSSEAndStartChangeStreamListener("/getPartnerOrganizationLeadsCCOnly", "/sse/listenToPartnerOrgLeadEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        onCellEditingStopped={this.onCellEditingStopped}
                        suppressClickEdit={true}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
