import axios from "../../utils/axiosInstance";

export const createDistributorSubgroupReactive = async (parentDistributorPartnerGroupId, groupName, adminUsernamesToAddToGroup, isReseller, isReferralAgent, canCreatePOC,
                                                        resellerDiscount, referralDiscount, daysUntilInvoiceDue, partnerMaxDaysForPOCTrialLicensesToExpire) => {
    return await axios.post("/createDistributorSubgroupReactive", {
        parentDistributorPartnerGroupId: parentDistributorPartnerGroupId,
        groupName: groupName,
        adminUsernamesToAddToGroup: adminUsernamesToAddToGroup,
        isReseller: isReseller,
        isReferralAgent: isReferralAgent,
        canCreatePOC: canCreatePOC,
        resellerDiscount: resellerDiscount,
        referralDiscount: referralDiscount,
        daysUntilInvoiceDue: daysUntilInvoiceDue,
        partnerMaxDaysForPOCTrialLicensesToExpire: partnerMaxDaysForPOCTrialLicensesToExpire
    });
};
export const promoteExistingGroupToSubgroupReactive = async (parentDistributorPartnerGroupId, groupIdToPromoteToSubgroup, isReseller, isReferralAgent, canCreatePOC,
                                                             resellerDiscount, referralDiscount, daysUntilInvoiceDue, partnerMaxDaysForPOCTrialLicensesToExpire) => {
    return await axios.post("/promoteExistingGroupToSubgroupReactive", {
        parentDistributorPartnerGroupId: parentDistributorPartnerGroupId,
        groupIdToPromoteToSubgroup: groupIdToPromoteToSubgroup,
        isReseller: isReseller,
        isReferralAgent: isReferralAgent,
        canCreatePOC: canCreatePOC,
        resellerDiscount: resellerDiscount,
        referralDiscount: referralDiscount,
        daysUntilInvoiceDue: daysUntilInvoiceDue,
        partnerMaxDaysForPOCTrialLicensesToExpire: partnerMaxDaysForPOCTrialLicensesToExpire,
    });
};

export const subgroupTurnChannelPartnerSettingOffReactive = async (zenGroupId) => {
    return await axios.post("/subgroupTurnChannelPartnerSettingOffReactive", {
        zenGroupId: zenGroupId
    });
};
export const changeSubgroupIsResellerReactive = async (zenGroupId, newSetting) => {
    return await axios.post("/changeSubgroupIsResellerReactive", {
        zenGroupId: zenGroupId,
        newSetting: newSetting
    });
};
export const changeSubgroupIsReferralAgentReactive = async (zenGroupId, newSetting) => {
    return await axios.post("/changeSubgroupIsReferralAgentReactive", {
        zenGroupId: zenGroupId,
        newSetting: newSetting
    });
};
export const changeSubgroupCanCreatePOCReactive = async (zenGroupId, newSetting) => {
    return await axios.post("/changeSubgroupCanCreatePOCReactive", {
        zenGroupId: zenGroupId,
        newSetting: newSetting
    });
};

export const changeSubgroupResellerDiscountReactive = async (zenGroupId, newDiscount) => {
    return await axios.post("/changeSubgroupResellerDiscountReactive", {
        zenGroupId: zenGroupId,
        newDiscount: newDiscount
    });
};

export const changeSubgroupReferralAgentDiscountReactive = async (zenGroupId, newDiscount) => {
    return await axios.post("/changeSubgroupReferralAgentDiscountReactive", {
        zenGroupId: zenGroupId,
        newDiscount: newDiscount
    });
};

export const changeSubgroupDaysUntilInvoiceDueReactive = async (zenGroupId, newDaysUntilDue) => {
    return await axios.post("/changeSubgroupDaysUntilInvoiceDueReactive", {
        zenGroupId: zenGroupId,
        newDaysUntilDue: newDaysUntilDue
    });
};

export const changeSubgroupMaxDaysUntilTrialLicensesExpireReactive = async (zenGroupId, partnerMaxDaysForPOCTrialLicensesToExpire) => {
    return await axios.post("/changeSubgroupMaxDaysUntilTrialLicensesExpireReactive", {
        zenGroupId: zenGroupId,
        partnerMaxDaysForPOCTrialLicensesToExpire: partnerMaxDaysForPOCTrialLicensesToExpire
    });
};

export const removeSubgroupFromParentDistributorReactive = async (zenGroupId) => {
    return await axios.post("/removeSubgroupFromParentDistributorReactive", {
        zenGroupId: zenGroupId
    });
};

export const getGroupsWithoutParentPartnerAssignedReactive = async () => {
    const response = await axios.post("/getGroupsWithoutParentPartnerAssignedReactive",{})
    return response.data
}

export const getDistributorManagementGridFilterStateReactive = async () => {
    const response =  await axios.post("/getDistributorManagementGridFilterStateReactive", {});
    return response.data;
};

export const updateDistributorManagementGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateDistributorManagementGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateDistributorManagementGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateDistributorManagementGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getDistributorManagementGridColumnStateReactive = async () => {
    const response =  await axios.post("/getDistributorManagementGridColumnStateReactive", {});
    return response.data;
};
export const updateDistributorManagementGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateDistributorManagementGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateDistributorManagementGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateDistributorManagementGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const updateDistributorManagementGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateDistributorManagementGridColumnModeReactive", {
        columnMode: columnMode
    });
};