import axios from "../../utils/axiosInstance";

export const createSalesEmailNotificationObjectReactive = async (zenGroupId, notifyAllGroupMembers, emailAddress, userSetName, timeInterval, dealCreated, dealDisabled, dealExpired, upcomingDealExpiration, invoiceCreated) => {
    return await axios.post("/createSalesEmailNotificationObjectReactive", {
        zenGroupId: zenGroupId,
        notifyAllGroupMembers: notifyAllGroupMembers,
        emailAddressToNotify: emailAddress,
        userSetName: userSetName,
        timeInterval: timeInterval,
        dealCreated: dealCreated,
        dealDisabled: dealDisabled,
        dealExpired: dealExpired,
        upcomingDealExpiration: upcomingDealExpiration,
        invoiceCreated: invoiceCreated
    });
};

export const getSalesNotificationsReactive = async () => {
    const response = await axios.post("/salesNotificationsListReactive", {
    });
    return response.data;
};

export const deleteSingleSalesNotificationReactive = async (salesNotificationId) => {
    return await axios.post("/deleteSingleSalesNotificationReactive", {
        salesNotificationId: salesNotificationId
    });
};
export const updateSalesNotificationTypeReactive = async (salesNotificationId, specificType) => {
    return await axios.post("/updateSalesNotificationTypeReactive", {
        salesNotificationId: salesNotificationId,
        specificType: specificType
    });
};
export const updateSalesNotificationTimeIntervalReactive = async (salesNotificationId, newTimeInterval) => {
    return await axios.post("/updateSalesNotificationTimeIntervalReactive", {
        salesNotificationId: salesNotificationId,
        newTimeInterval: newTimeInterval
    });
};
export const updateSalesNotificationNameReactive = async (newName, salesNotificationId) => {
    return await axios.post("/updateSalesNotificationNameReactive", {
        newName: newName,
        salesNotificationId: salesNotificationId
    });
};

export const getSalesNotificationsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getSalesNotificationsGridFilterStateReactive", {});
    return response.data;
};

export const updateSalesNotificationsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateSalesNotificationsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateSalesNotificationsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateSalesNotificationsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const findBySalesNotificationIdListReactive = async (ids) => {
    const response = await axios.post("/findBySalesNotificationIdListReactive",{
        ids
    })
    return response.data
}

export const getSalesNotificationsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getSalesNotificationsGridColumnStateReactive", {});
    return response.data;
};
export const updateSalesNotificationsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateSalesNotificationsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateSalesNotificationsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateSalesNotificationsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const updateSalesNotificationsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateSalesNotificationsGridColumnModeReactive", {
        columnMode: columnMode
    });
};