import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import PortraitIcon from '@mui/icons-material/Portrait';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Modal from "react-modal";
import {Autocomplete, Button, IconButton, TextField, ThemeProvider} from "@mui/material";
import {autocompleteTheme, buttonTheme} from "../../utils/muiStyling";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    launchLinkedInCompanyScraperPhantomBusterReactive,
    launchSalesNavigatorEmployeeExportPhantomBusterReactive
} from "../api/ccOnlyLinkedInCompanyProfilesApi";
//TODO: Add min/med/max

export default function CCOnlyLinkedInCompanyProfiles() {
    const [isLoading, setIsLoading] = useState(false);
    const [phantomBusterModalIsOpen, setPhantomBusterModalIsOpen] = useState(false);
    const [linkedInList, setLinkedInList] = useState([]);
    const [linkedInSessionCookie, setLinkedInSessionCookie] = useState("");
    const [employeePhantomBusterModalIsOpen, setEmployeePhantomBusterModalIsOpen] = useState(false);
    const [bulkEmployeePhantomBusterModalIsOpen, setBulkEmployeePhantomBusterModalIsOpen] = useState(false);
    const [enableButtons, setEnableButtons] = useState(false);
    const [employeeAgentCompanyName, setEmployeeAgentCompanyName] = useState("");
    const [employeeAgentLinkedInCompanyUrl, setEmployeeAgentLinkedInCompanyUrl] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [columnDefs, setColumnDefs] = useState([
        { field: "dateCollected", headerName: "Date Collected",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        {
            field: "name", initialWidth: 300, headerName: "Company Name",
            filter: 'agSetColumnFilter', filterParams: {buttons: ["reset", "apply", "cancel"],},
            sortable: true
        },
        {
            field: "companySize", initialWidth: 230, headerName: "Company Size",
            filter: 'agSetColumnFilter', filterParams: {buttons: ["reset", "apply", "cancel"],},
            sortable: true
        },
        {
            field: "linkedInCompanyUrl", initialWidth: 450, headerName: "LinkedIn Company Url",
            filter: 'agTextColumnFilter', filterParams: defaultClientSideTextFilterParams,
            cellRenderer: function (params) {
                let tooltipDiv = ""
                if(params.value){
                    tooltipDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-up-right-from-square" size="xs"/>}
                        onClick={() => {
                            window.open(params.value, "_blank", "noreferrer")
                        }}
                        tooltipTitle={`Click to Open in New Tab`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        {tooltipDiv}
                        {params.value}
                    </div>
                )
            },
            sortable: true
        },
        {
            field: "runSalesNavEmployeeProfilesAgent", headerName: "Run LinkedIn Employee Profile Scraper",
            sortable: false, editable: false,
            initialWidth: 375,
            cellRenderer: function (params) {
                if(params.node.data.linkedInCompanyUrl && params.node.data.linkedInCompanyUrl.trim().length > 0){
                    return (
                        <div className={`flex flex-row items-center gap-x-1`}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <IconButton sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}>
                                        <FontAwesomeIcon size="xs" className="object-contain mr-0" icon="fa-duotone fa-user-tie" color={"black"}/>
                                    </IconButton>
                                }
                                onClick={() => {
                                    resetEmployeePhantomBusterAgentModal()
                                    setEmployeeAgentLinkedInCompanyUrl(params.node.data.linkedInCompanyUrl)
                                    setEmployeeAgentCompanyName(params.node.data.name)
                                    setEmployeePhantomBusterModalIsOpen(true)
                                }}
                                enterDelayMillis={1000}
                                tooltipTitle={<div>Click to run the LinkedIn Decision Maker Employee Profile Scraper for this company</div>}
                                tooltipPlacement={"bottom-start"}
                            />
                            Run Decision Maker Employee Scraper
                        </div>
                    )
                }
                else{
                    return null
                }
            }
        },
        { field: "dateLastEmployeeProfilesScraped", headerName: "Date Last Employee Profiles Scrape",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        {
            field: "websiteUrl", initialWidth: 320, headerName: "Website Url",
            filter: 'agTextColumnFilter', filterParams: defaultClientSideTextFilterParams,
            cellRenderer: function (params) {
                let tooltipDiv = ""
                if(params.value){
                    tooltipDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-up-right-from-square" size="xs"/>}
                        onClick={() => {
                            window.open(params.value, "_blank", "noreferrer")
                        }}
                        tooltipTitle={`Click to Open in New Tab`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        {tooltipDiv}
                        {params.value}
                    </div>
                )
            },
            sortable: true
        },
        {
            field: "salesNavigatorLink", initialWidth: 450, headerName: "Sales Navigator Link",
            filter: 'agTextColumnFilter', filterParams: defaultClientSideTextFilterParams,
            cellRenderer: function (params) {
                let tooltipDiv = ""
                if(params.value){
                    tooltipDiv = <MuiIconButtonWithTooltip
                        icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-up-right-from-square" size="xs"/>}
                        onClick={() => {
                            window.open(params.value, "_blank", "noreferrer")
                        }}
                        tooltipTitle={`Click to Open in New Tab`}
                        tooltipPlacement={"bottom-start"}
                    />
                }
                return (
                    <div className={`flex flex-row items-center gap-x-1`}>
                        {tooltipDiv}
                        {params.value}
                    </div>
                )
            },
            sortable: true
        },
        {
            field: "headquarters", initialWidth: 250, headerName: "Headquarters",
            filter: 'agSetColumnFilter', filterParams: {buttons: ["reset", "apply", "cancel"],},
            sortable: true
        },
        {
            field: "location", initialWidth: 230, headerName: "Location",
            filter: 'agSetColumnFilter', filterParams: {buttons: ["reset", "apply", "cancel"],},
            sortable: true
        },
        {
            field: "address", initialWidth: 500, headerName: "Address",
            filter: 'agTextColumnFilter', filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        {
            field: "domain", initialWidth: 300, headerName: "Domain",
            filter: 'agTextColumnFilter', filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        {
            field: "employeesOnLinkedIn", initialWidth: 300, headerName: "Employees On LinkedIn",
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        {
            field: "phoneNumber", initialWidth: 300, headerName: "Phone Number",
            filter: 'agTextColumnFilter', filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "queryTimestamp", headerName: "Date of PB Agent Query",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(325)
    }, []);

    function resetPhantomBusterModal(){
        setPhantomBusterModalIsOpen(false)
        setLinkedInList([])
        setLinkedInSessionCookie("")
    }

    function submitPhantomBusterModal(){
        if(linkedInList === null || linkedInList === undefined || linkedInList.length <= 0){
            NotificationManager.info(`You Must Enter at Least 1 URL`);
            return
        }
        let cookieToSend = null
        if(linkedInSessionCookie !== null && linkedInSessionCookie !== undefined && linkedInSessionCookie.trim().length > 0){
            cookieToSend = linkedInSessionCookie
        }
        setIsLoading(true)
        launchLinkedInCompanyScraperPhantomBusterReactive(linkedInList, cookieToSend).then(function(response){
            setIsLoading(false)
            NotificationManager.success("Successfully sent request");
            resetPhantomBusterModal()
        }).catch(function(error){
            setIsLoading(false)
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Unexpected error making request")
            }
        })

    }

    function resetEmployeePhantomBusterAgentModal(){
        setEmployeePhantomBusterModalIsOpen(false)
        setBulkEmployeePhantomBusterModalIsOpen(false)
        setEmployeeAgentLinkedInCompanyUrl("")
        setEmployeeAgentCompanyName("")
        setLinkedInSessionCookie("")
    }

    function submitEmployeePhantomBusterModal(){
        if(employeeAgentLinkedInCompanyUrl === null || employeeAgentLinkedInCompanyUrl === undefined || employeeAgentLinkedInCompanyUrl.length <= 0){
            NotificationManager.info(`Unexpected error making request`);
            return
        }
        let cookieToSend = null
        if(linkedInSessionCookie !== null && linkedInSessionCookie !== undefined && linkedInSessionCookie.trim().length > 0){
            cookieToSend = linkedInSessionCookie
        }
        setIsLoading(true)
        launchSalesNavigatorEmployeeExportPhantomBusterReactive([employeeAgentLinkedInCompanyUrl], cookieToSend).then(function(response){
            setIsLoading(false)
            NotificationManager.success("Successfully sent request");
            resetEmployeePhantomBusterAgentModal()
        }).catch(function(error){
            setIsLoading(false)
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Unexpected error making request")
            }
        })
    }

    function submitBulkPhantomBusterModal(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let urlsToSend = []
            gridApi.getSelectedNodes().forEach(rowNode => {
                if(rowNode.data.linkedInCompanyUrl && rowNode.data.linkedInCompanyUrl.trim().length > 0){
                    urlsToSend.push(rowNode.data.linkedInCompanyUrl.trim())
                }
            })
            if(urlsToSend.length < 1){
                NotificationManager.info(`No valid LinkedIn Company Profile URLs selected`);
                return
            }

            let cookieToSend = null
            if(linkedInSessionCookie !== null && linkedInSessionCookie !== undefined && linkedInSessionCookie.trim().length > 0){
                cookieToSend = linkedInSessionCookie
            }
            setIsLoading(true)
            launchSalesNavigatorEmployeeExportPhantomBusterReactive(urlsToSend, cookieToSend).then(function(response){
                setIsLoading(false)
                NotificationManager.success("Successfully sent request");
                resetEmployeePhantomBusterAgentModal()
            }).catch(function(error){
                setIsLoading(false)
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error making request")
                }
            })
        }
        else{
            NotificationManager.info(`Unexpected error making request`);
        }
    }


    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Phantom Buster LinkedIn Company Profiles</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <Modal contentLabel="Run Phantom Buster Agent for LinkedIn Company Profiles"
                       isOpen={phantomBusterModalIsOpen}
                       onRequestClose={() => {
                           resetPhantomBusterModal()
                       }}
                       shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Run Phantom Buster Agent for LinkedIn Company
                                Profiles</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetPhantomBusterModal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        {/*Form content*/}
                        <div className="flex flex-col gap-y-5">
                            <label>Each LinkedIn company url entered will be ran through our LinkedIn Company Scraper
                                Phantom Buster Agent. The results will be added to the grid on this page.</label>
                            <label>Phantom Buster will automatically send an email to us for failed Agent runs.</label>
                        </div>
                        <hr className="h-0.5"/>
                        <div>
                            <label>After entering the url into the input, you must click the Enter button for
                                url to be added to the list</label>
                        </div>
                        <ThemeProvider theme={autocompleteTheme}>
                            <Autocomplete
                                className="" disablePortal={true} freeSolo openOnFocus={true} size={"small"}
                                options={[]} //No actual options, its just entering the urls as input
                                multiple
                                renderInput={(params) => <TextField {...params}
                                                                    label={"Required - LinkedIn Company URLs, You May Enter Multiple"}/>}
                                onChange={(event, valueList) => {
                                    setLinkedInList(valueList)
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') { //Don't submit form if enter key pressed in input
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </ThemeProvider>
                        <hr className="h-0.5"/>
                        <div className="flex flex-col gap-y-5">
                            <label>We use a saved LinkedIn session cookie to run the Phantom Buster Agent, but the
                                cookie may expire. If a new cookie is needed, enter it below</label>
                        </div>
                        <TextField
                            size={"small"}
                            id="outlined-controlled"
                            label="Optional - LinkedIn Session Cookie"
                            value={linkedInSessionCookie}
                            onChange={(event) => {
                                setLinkedInSessionCookie(event.target.value);
                            }}
                        />
                        <div className="flex flex-col">
                            <ThemeProvider theme={buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}
                                        onClick={() => {
                                            submitPhantomBusterModal()
                                        }}
                                >
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </Modal>
                <Modal contentLabel="Run Phantom Buster Agent for LinkedIn Decision Maker Employee Profiles"
                       isOpen={employeePhantomBusterModalIsOpen}
                       onRequestClose={() => {
                           resetEmployeePhantomBusterAgentModal()
                       }}
                       shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Run Phantom Buster Agent for LinkedIn Decision Maker Employee Profiles</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetEmployeePhantomBusterAgentModal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        {/*Form content*/}
                        <div className="flex flex-col gap-y-5">
                            <label><label className="font-bold">Company Name: </label>{employeeAgentCompanyName}</label>
                            <label className={"break-all"}><label className="font-bold">LinkedIn Company URL: </label>{employeeAgentLinkedInCompanyUrl}</label>
                        </div>
                        <hr className="h-0.5"/>
                        <div className="flex flex-col gap-y-5">
                            <label>We use a saved LinkedIn Sales Navigator session cookie to run the Phantom Buster Agent, but the cookie may expire. If a new cookie is needed, enter it below</label>
                        </div>
                        <TextField
                            size={"small"}
                            id="outlined-controlled"
                            label="Optional - LinkedIn Session Cookie"
                            value={linkedInSessionCookie}
                            onChange={(event) => {
                                setLinkedInSessionCookie(event.target.value);
                            }}
                        />
                        <div className="flex flex-col">
                            <ThemeProvider theme={buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}
                                        onClick={() => {
                                            submitEmployeePhantomBusterModal()
                                        }}
                                >
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </Modal>
                <Modal contentLabel="Bulk Run Phantom Buster Agent for LinkedIn Decision Maker Employee Profiles"
                       isOpen={bulkEmployeePhantomBusterModalIsOpen}
                       onRequestClose={() => {
                           resetEmployeePhantomBusterAgentModal()
                       }}
                       shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
                >
                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Bulk Run Phantom Buster Agent for LinkedIn Decision Maker
                                Employee Profiles</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetEmployeePhantomBusterAgentModal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5"/>
                        {/*Form content*/}
                        <div className="flex flex-col gap-y-2">
                            <label>Each selected company on the grid will be sent to run the Phantom Buster
                                Agent.</label>
                            <label>Note that a max amount of 10 companies can be sent at a time to help with
                                rate limiting and reaching the max parallelism for Phantom Buster.</label>
                            <labeL>A small delay between each launch will be added as well to help with this</labeL>
                        </div>
                        <hr className="h-0.5"/>
                        <div className="flex flex-col gap-y-5">
                            <label>We use a saved LinkedIn Sales Navigator session cookie to run the Phantom Buster
                                Agent, but the cookie may expire. If a new cookie is needed, enter it below</label>
                        </div>
                        <TextField
                            size={"small"}
                            id="outlined-controlled"
                            label="Optional - LinkedIn Session Cookie"
                            value={linkedInSessionCookie}
                            onChange={(event) => {
                                setLinkedInSessionCookie(event.target.value);
                            }}
                        />
                        <div className="flex flex-col">
                            <ThemeProvider theme={buttonTheme}>
                                <Button type={"submit"} color={"primary"} variant={"contained"}
                                        onClick={() => {
                                            submitBulkPhantomBusterModal()
                                        }}
                                >
                                    Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </Modal>
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Phantom Buster LinkedIn Company Profiles")}
                    <hr className="bg-black h-0.5"/>
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                        <MuiIconButtonWithTooltipAndBox
                                icon={<LinkedInIcon className={"cursor-pointer"}/>}
                                tooltipTitle={"Run Phantom Buster Agent for LinkedIn Company Profiles"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetPhantomBusterModal()
                                    setPhantomBusterModalIsOpen(true)
                                }}/>
                            <MuiIconButtonWithTooltipAndBox
                                icon={
                                    <IconButton sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}>
                                        <FontAwesomeIcon className="mt-1 object-contain" icon="fa-duotone fa-user-tie" size="sm" color={`${!enableButtons ? "#C1c1c1" : "black"}`}/>
                                    </IconButton>
                                }
                                disabled={!enableButtons}
                                tooltipTitle={"Bulk Run Phantom Buster Agent for LinkedIn Employee Profiles for Selected Companies"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetEmployeePhantomBusterAgentModal()
                                    setBulkEmployeePhantomBusterModalIsOpen(true)
                                }}/>

                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClearRefresh gridApi={gridApi} showRefreshIcon={false} showExcelExportIcon={true}
                                          sseDataPullActive={sseDataPullActive} excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            excelExport={excelExport}
                            setIsLoading={setIsLoading}
                            setEnableButtons={setEnableButtons}
                        />
                        <Footer/>
                    </div>
                </div>
            </div>

            <NotificationContainer/>
        </div>
    );

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "phantomBusterLinkedInCompanyProfilesGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing able to edit yet
    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Default sort to sort on dateCollected desc.
        if(gridReadyParams && gridReadyParams.api){
            gridReadyParams.api.applyColumnState({
                state: [{colId: "dateCollected", sort: "desc"}]
            })
        }

        await loadDataWithSSEAndStartChangeStreamListener("/getLinkedInCompanyProfiles", "/sse/listenToLinkedInCompanyProfilesEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        onCellEditingStopped={this.onCellEditingStopped}
                        suppressClickEdit={true}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
