import React, {Component, useEffect, useMemo, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {MultiFilterModule} from "@ag-grid-enterprise/multi-filter";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    checkIncidentHasRelatedProcessInjectionsAndCreationsReactive,
    createSilentIncidentsFromIncidentsPageReactive,
    findSilentIncidentNamesForSingleIncidentIdReactive,
    findSuggestedPathsReactive,
    findSuggestedSilentIncidentPathsReactive,
    getCCDiffFileReactive,
    getDetailRowsForIncidentTimeSeriesReactive,
    incidentsPerGroupCountReactive,
    incidentTimeSeriesListReactive,
    submitIncidentForReviewReactive,
    updateDataIncidentChartVisibilityReactive,
    updateIncidentsGridColumnModeReactive,
    updateIncidentsGridColumnStateReactive,
    updateIncidentsGridFilterModelReactive,
    updateIncidentsGridUseColumnStateReactive,
    updateIncidentsGridUseFilterStateReactive,
    userAddWhitelistsFromIncidentsPageReactive,
    userSetIncidentAsNotSilentReactive
} from "../api/incidentsApi";
import Modal from "react-modal";
import {findBySingleWhitelistAppliedIdReactive} from "../api/tailoredBehaviorsApi";
import SidebarMenu from "../../components/sideBarComponent";
import {useForm} from "react-hook-form";
import {defaultZenGroupColumnInitWithOptionsWithValueGetter} from "../../utils/zenGroupDisplayNameGridHelper";
import {defaultAgentNameColumnInitWithOptions} from "../../utils/agentNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {incidentPageCellEditingStopped} from "../../utils/gridCellEditing";
import {AgChartsReact} from "ag-charts-react";
import {agentVersionFormatter} from "../../utils/agentVersionFormatter";
import {Link, useLocation} from "react-router-dom"
import {findZenGroupById, useZenGroupSessionStorage} from "../../utils/zenGroupSessionStorageManager";
import {masterFilterHelper} from "../../utils/filterHelper";
import {
    dataIncidentChartVisibleSessionVariable,
    getChartVisibilitySettingInSession,
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnStateForIncidentsGrid,
    updateChartVisibilitySettingInSession,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    incidentNameCellRendererFrameworkForIncidentsGrid,
    removePathFromStartOfProgramArgumentsHelper
} from "../../utils/incidentNameGridHelper";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {base64DecodedValueFormatter} from "../../utils/gridBase64ValueFormatter";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {getAgentFriendlyNamesReactive} from "../api/licensesApi";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {buttonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconWithTooltip
} from "../../components/muiComponents";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import TimerOffOutlinedIcon from "@mui/icons-material/TimerOffOutlined";
import VpnKeyOffOutlinedIcon from "@mui/icons-material/VpnKeyOffOutlined";
import {findNotificationEventForDataIncidentAutoFilter} from "../api/notificationEventApi";
import {decryptAndGetSessionVariable} from "../../utils/storageHelper";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import {InformationModal} from "../../components/informationOnlyModal";
import {BrowserUtilityProcessTuningModal} from "../../components/responseTuningComponents";
import {fileTriggersToMatchAllOptions, handleFileTriggerTypesSelectedForCreateWhitelist} from "./tailoredBehaviors";

let gridColumnStateSessionVariableName = "incidentsGridColumnState"
let fileAttributesString = "File Attributes"
let chartSessionVariableName = dataIncidentChartVisibleSessionVariable
let minColumnIds = ["incidentsCount", "zenGroupDisplayName", "machineName", "pathEncoded", "dateCreatedRounded", "whitelistAppliedId"]
let medColumnIds = ["incidentsCount", "zenGroupDisplayName", "machineName", "pathEncoded", "programArgumentsEncoded", "parentPathEncoded", "dateCreatedRounded", "whitelistAppliedId"]
let chromeUtilityArgsToShowTuningModal = [
    "--type=utility --utility-sub-type=chrome.mojom.UtilWin",
    "--type=utility --utility-sub-type=data_decoder.mojom",
    "--type=utility --utility-sub-type=quarantine.mojom.Quarantine",
    "--type=utility --utility-sub-type=chrome.mojom.DocumentAnalysisService",
    "--type=utility --utility-sub-type=patch.mojom.FilePatcher",
    "--type=utility --utility-sub-type=storage.mojom.StorageService",
    "--type=utility --utility-sub-type=network.mojom.NetworkService",
    "--type=utility --utility-sub-type=media.mojom.SpeechRecognitionService",
    "--type=utility --utility-sub-type=audio.mojom.AudioService",
    "--type=utility --utility-sub-type=chrome.mojom.ProcessorMetrics",
    "--type=utility --utility-sub-type=unzip.mojom.Unzipper",
    "--type=utility --utility-sub-type=video_capture.mojom.VideoCaptureService",
    "--type=utility --utility-sub-type=proxy_resolver.mojom.ProxyResolverFactory",
    "--type=utility --utility-sub-type=printing.mojom.PrintingService",
    "--type=utility --utility-sub-type=printing.mojom.PrintCompositor",
    "--type=utility --utility-sub-type=edge_collections.mojom.CollectionsDataManager",
    "--type=utility --utility-sub-type=device.mojom.XRDeviceService",
    "--type=utility --utility-sub-type=media.mojom.MediaFoundationServiceBroker",
    "--type=utility --utility-sub-type=chrome.mojom.FileUtilService",
    "--type=utility --utility-sub-type=media.mojom.CdmServiceBroker",
    "--type=utility --utility-sub-type=auction_worklet.mojom.AuctionWorkletService"
]
const processData = (data, forWhitelistModal=false) => {
    let memString = ""
    const memVal = data.memory
    memString = memVal;
    if (memVal > 1000) {
        memString = (memVal / 1000).toFixed(2) + " KB"
    }
    if (memVal > 1000000) {
        memString = (memVal / 1000000).toFixed(2) + " MB"
    }
    if (memVal > 1000000000) {
        memString = (memVal / 1000000000).toFixed(2) + " GB"
    }
    if(data.lastResponseTimestamp){
        data.lastResponseTimestamp = new Date(data.lastResponseTimestamp).toUTCString()
    }

    let programArgumentsString = ""
    let parentProgramArgumentsString = ""

    if(data) {
        programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
        parentProgramArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.parentPath, data.parentProgramArguments)
    }

    data.programArgumentsEditedString = programArgumentsString
    data.parentProgramArgumentsString = parentProgramArgumentsString
    let gridData
    if(forWhitelistModal){
        //for whitelist modal, don't need to include all fields
        gridData = {
            "Response Name": data.incidentDisplayName,
            "Program Path": data.path,
            "Program Arguments": programArgumentsString,
            "Parent Program Path": data.parentPath,
            "Parent Program Arguments": parentProgramArgumentsString
        };
    }
    else{
        //else for details modal, include additional fields
        gridData = {
            "Response Name": data.incidentDisplayName,
            "Machine Name": data.machineName,
            "Program Path": data.path,
            "Program Arguments": programArgumentsString,
            "Program File Hash": data.fileHash,
            "Parent Program Path": data.parentPath,
            "Parent Program Arguments": parentProgramArgumentsString,
            "Ram usage": memString,
            "Pid": data.pid
        };
    }

    return Object.keys(gridData).map((key) => ({
        description: key,
        value: gridData[key],
    }))
};

let whitelistedFilterValueOptions = ['Excluded', 'Not Excluded']

export default function Incidents() {
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [activeIncident, setActiveIncident] = useState();
    const [clickedIconIncidentData, setClickedIconIncidentData] = useState();
    const [showNoSuggestedPathsFoundIcon, setShowNoSuggestedPathsFoundIcon] = useState(false);
    const [showNoSuggestedSilentIncidentPathsFoundIcon, setShowNoSuggestedSilentIncidentPathsFoundIcon] = useState(false);
    const [openResponseHistoryModal, setOpenResponseHistoryModal] = useState();
    // eslint-disable-next-line no-unused-vars
    const [ignoreButtonDisabled, setIgnoreButtonDisabled] = useState(true);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    const [moreInformationModalOpen, setMoreInformationModalOpen] = useState(false);
    const [moreInfoModalText, setMoreInfoModalText] = useState(<div></div>);
    // eslint-disable-next-line no-unused-vars
    const { register, handleSubmit, errors, watch, reset } = useForm();
    const [gridApi, setGridApi] = useState(null);
    const [suggestedPaths, setSuggestedPaths] = useState([]);
    const [selectedPaths, setSelectedPaths] = useState([]);
    const [ignoreSubmitButtonDisabled, setIgnoreSubmitButtonDisabled] = useState(false);
    const [whitelistModalIncludeProgramArgs, setWhitelistModalIncludeProgramArgs] = useState(false);
    const [whitelistModalIncludeParentPath, setWhitelistModalIncludeParentPath] = useState(false);
    const [whitelistModalIncludeParentProgramArgs, setWhitelistModalIncludeParentProgramArgs] = useState(false);
    const [newWhitelistIncidentMatchTypeList, setNewWhitelistIncidentMatchTypeList] = useState(fileTriggersToMatchAllOptions); //default to all
    const [temporaryWhitelistToggled, setTemporaryWhitelistToggled] = useState(false);
    const [additionalRestrictionsToggled, setAdditionalRestrictionsToggled] = useState(false);
    const [onlyMatchIfTrustedCert, setOnlyMatchIfTrustedCert] = useState(false);
    const [onlyMatchIfNoInjections, setOnlyMatchIfNoInjections] = useState(false);
    const [onlyMatchIfNoModifiedMemory, setOnlyMatchIfNoModifiedMemory] = useState(false);
    const [silentIncidentTurnOnModalButtonDisabled, setSilentIncidentTurnOnModalButtonDisabled] = useState(false);
    const [showCertificateModal, setShowCertificateModal] = useState(false);
    const [chartDataWithoutWhitelistedCount, setChartDataWithoutWhitelistedCount] = useState([]);
    const [chartDataWithWhitelistedCount, setChartDataWithWhitelistedCount] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartToggled, setChartToggled] = useState(getChartVisibilitySettingInSession(chartSessionVariableName));
    const [includeWhitelistedToggled, setIncludeWhitelistedToggled] = useState(false);
    const [includeWhitelistedToggleDisabled, setIncludeWhitelistedToggleDisabled] = useState(true);
    const [zenGroupForTuningModal, setZenGroupForTuningModal] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("incidentsGridFilterState"));
    const [showTurnIncidentSilentModal, setShowTurnIncidentSilentModal] = useState(false);
    const [tuningModalIsOpen, setTuningModalIsOpen] = useState(false);
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [detailRowHeight, setDetailRowHeight] = useState(500);
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const incidentLocation = useLocation();

    // eslint-disable-next-line no-unused-vars
    const [initialGridColumnDefs, setInitialGridColumnDefs] = useState([
        { field: "incidentsCount", headerName: "Number of Incidents", initialWidth: 250,
            cellRenderer: 'agGroupCellRenderer',
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 1,
            },
            sortable: true,
            pinned: 'left',
            hide: false,
        },
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        defaultAgentNameColumnInitWithOptions(true),
        { field: "machineName", headerName: "Machine Name", initialWidth: 225,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            cellRenderer:function(params){
                let agentLinkDiv = ""
                if(params.data.agentId){
                    //only using agentId for cross-link filter now so only need to check agentId exists
                    agentLinkDiv =
                        <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                            agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This Agent"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </Link>
                }
                return(
                    <div className={"flex flex-nowrap items-center gap-x-2"}>
                        {agentLinkDiv}
                        {params.value}
                    </div>
                )
            },
            sortable: true,
        },
        { field: "pathEncoded", headerName: "Program Path", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            valueFormatter: base64DecodedValueFormatter,
            cellRenderer:function(params){
                let detailsDiv = ""
                //check for chrome path and args for the more info modal, check edgewebview as well
                if(params.valueFormatted && (params.valueFormatted.toLowerCase() === "C:\\Program Files\\Google\\Chrome\\Application\\chrome.exe".toLowerCase()
                    || params.valueFormatted.toLowerCase() === "C:\\Program Files (x86)\\Google\\Chrome\\Application\\chrome.exe".toLowerCase()
                    || /C:\\Program Files.*\\Microsoft\\EdgeWebView\\Application\\.+\\msedgewebview2\.exe/i.test(params.valueFormatted)
                )){
                    let validArgs = false
                    try{
                        let args = atob(params.data.programArgumentsEncoded).toLowerCase()
                        validArgs = checkChromeProgramArgumentsForTuningModal(args)
                    }
                    catch(error){}
                    if(validArgs){
                        detailsDiv = <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon className={`object-contain`} icon={"fa-duotone fa-music-note"} size="xs" color={`black`}/>
                            }
                            tooltipTitle={"Tuning Available"} tooltipPlacement={"bottom-start"}
                            onClick={() => {
                                if(params.node.data.zenGroupId){
                                    setZenGroupForTuningModal(params.node.data.zenGroupId)
                                }
                                else{
                                    setZenGroupForTuningModal(null)
                                }
                                setTuningModalIsOpen(true)
                            }}
                        />
                    }
                }
                else if(params.valueFormatted && params.valueFormatted.toLowerCase().trim() === "C:\\Windows\\System32\\WindowsPowerShell\\v1.0\\powershell.exe".toLowerCase()){ //check for powershell path and windows ccm program args
                    let validArgs = false
                    try{
                        let args = atob(params.data.programArgumentsEncoded).toLowerCase()
                        if(args.includes("-ExecutionPolicy Bypass \"& 'C:\\WINDOWS\\CCM\\SystemTemp\\".toLowerCase())){
                            validArgs = true
                        }
                    }
                    catch(error){}

                    let validParentPath = false
                    try{
                        let parentPath = atob(params.data.parentPathEncoded).toLowerCase()
                        if(parentPath && parentPath.trim() === "C:\\Windows\\System32\\wbem\\WmiPrvSE.exe".toLowerCase()){
                            validParentPath = true
                        }
                    } catch (e) {}
                    if(validArgs && validParentPath){
                        detailsDiv = <MuiIconButtonWithTooltip
                            icon={<PrivacyTipIcon color={"action"} className={"cursor-pointer"} />}
                            tooltipTitle={"More Info"}
                            tooltipPlacement={"top"}
                            onClick={() => {
                                let textDiv =
                                    <div className={"flex flex-col gap-y-3"}>
                                        <div>Microsoft Configuration Manager can be used as tool to manage your devices and perform various tasks, which includes the ability to create and
                                            run powershell scripts.</div>
                                        <div>
                                            These scripts may access data iteratively, please ensure sure you recognize any scripts running on your machines.
                                        </div>
                                        <div>
                                            Click <a href={"https://learn.microsoft.com/en-us/mem/configmgr/apps/deploy-use/create-deploy-scripts#script-monitoring"} rel="noreferrer" target="_blank"
                                                     className={"text-blue-600 underline dark:text-blue-500 hover:no-underline"}>
                                            here</a> for more information about the Configuration Manager scripts, or speak to a Cyber Crucible threat hunter for more details as necessary.
                                        </div>
                                    </div>
                                setMoreInfoModalText(textDiv)
                                setMoreInformationModalOpen(true)
                            }}
                        />
                    }
                }
                return(
                    <div className={"flex flex-nowrap items-center gap-x-1"}>
                        {detailsDiv}
                        {params.valueFormatted}
                    </div>
                )
            },
            sortable: true
        },
        { field: "programArgumentsEncoded", headerName: "Program Arguments", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            //valueFormatter: base64DecodedValueFormatter,
            valueFormatter:
                function(params){
                    if(params.node.data) {
                        let path = ""
                        let programArgs = ""
                        if(params.node.data.pathEncoded){
                            try{
                                path = atob(params.node.data.pathEncoded)
                            } catch (e) {}
                        }
                        if(params.node.data.programArgumentsEncoded){
                            try{
                                programArgs = atob(params.node.data.programArgumentsEncoded)
                            } catch (e) {}
                        }
                        try{
                            return removePathFromStartOfProgramArgumentsHelper(path, programArgs)
                        }
                        catch(error){
                            return null
                        }
                    }
                    else{
                        return ""
                    }
                },
            sortable: true
        },
        { field: "parentPathEncoded", headerName: "Parent Program Path", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            valueFormatter: base64DecodedValueFormatter,
            sortable: true
        },
        { field: "parentProgramArgumentsEncoded", headerName: "Parent Program Arguments", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],
                filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                maxNumConditions: 1,
            },
            valueFormatter:
                function(params){
                    if(params.node.data) {
                        let path = ""
                        let programArgs = ""
                        if(params.node.data.parentPathEncoded){
                            try{
                                path = atob(params.node.data.parentPathEncoded)
                            } catch (e) {}
                        }
                        if(params.node.data.parentProgramArgumentsEncoded){
                            try{
                                programArgs = atob(params.node.data.parentProgramArgumentsEncoded)
                            } catch (e) {}
                        }
                        try{
                            return removePathFromStartOfProgramArgumentsHelper(path, programArgs)
                        }
                        catch(error){
                            return null
                        }
                    }
                    else{
                        return ""
                    }
                },
            sortable: true
        },
        { field: "dateCreatedRounded", headerName: "Created", initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "whitelistAppliedId", headerName: "Excluded", initialWidth: 200,
            filter: 'agMultiColumnFilter',
            filterParams: {
                //allow for two filters to show in filter menu, one for excluded, and one for silent
                filters: [
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ["reset", "apply", "cancel"],
                            values: whitelistedFilterValueOptions,
                            suppressSorting: true,
                        },
                    },
                    {
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            buttons: ["reset", "apply", "cancel"],
                            values: ['Silent', 'Not Silent'],
                            suppressSorting: true,
                        },
                    },
                ],
            },
            valueFormatter: function(params){
                if(params.value){
                    return "Excluded"
                }
                else{
                    return ""
                }
            },
            sortable: false
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(300)
    }, []);
    //chart options for included and not including whitelisted incidents
    let chartWithoutWhitelistedCountOptions={
        autoSize: true,
        data: chartDataWithoutWhitelistedCount,
        title: {
            text: 'Responses Per Group',
            fontSize: 18,
        },
        subtitle: {
            text: 'Over the last 30 days',
            fontSize: 15,
            spacing: 15
        },
        series: [
            {
                type: 'bar',
                xKey: 'zenGroupId',
                yKey: 'countWithoutWhitelisted',
                yName:  'Number of Responses',
                fill: '#e76a24',
                stroke: '#c2c2c2',
                highlightStyle: {
                    item: {
                        fill: "#E8E8E8",
                        stroke: "#181818",
                        strokeWidth: 1
                    },
                },
                tooltip: { //for hovering over a column
                    renderer: function (params) {
                        let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                        return {
                            title: params.yName,
                            content: content
                        };
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                keys: ["zenGroupId"],
                label: {
                    rotation: 50,
                    formatter: function(params){
                        //The params include the zenGroupId (params.value) and the index of the label in the chart data array, so we can access the object and format return value
                        let index = params.index //index of the chart data array
                        if(chartDataWithoutWhitelistedCount && index !== undefined && index !== null){
                            try{
                                let object = chartDataWithoutWhitelistedCount[index]
                                if(object){
                                    let name = params.value //initialize to zenGroupId (params.value) in case no zenGroupName found
                                    if(object.zenGroupName){
                                        name = object.zenGroupName
                                    }
                                    if(object.countWithoutWhitelisted !== null && object.countWithoutWhitelisted !== undefined){
                                        return `${name} (${object.countWithoutWhitelisted})`
                                    }
                                    else{
                                        //should not get to here but default to return name
                                        return name
                                    }
                                }
                            } catch (e) {}
                        }
                        return params.value //if we get to here then something went wrong, return zenGroupId (params.value)
                    },
                    fontSize: 11
                },
            },
            {
                type: 'number',
                position: 'left',
            },
        ],
        legend: {
            spacing: 40,
            position: 'top',
            enabled: true
        },
    }
    let chartWithWhitelistedCountOptions={
        autoSize: true,
        data: chartDataWithWhitelistedCount,
        title: {
            text: 'Responses Per Group',
            fontSize: 18,
        },
        subtitle: {
            text: 'Over the last 30 days',
            fontSize: 15,
            spacing: 15
        },
        series: [
            {
                type: 'bar',
                stacked: true,
                xKey: 'zenGroupId',
                yKey: 'countWithoutWhitelisted',
                yName: 'Number of Responses',
                fill: '#e76a24',
                stroke: '#c2c2c2',
                strokeWidth: 1,
                highlightStyle: {
                    item: {
                        fill: "#E8E8E8",
                        stroke: "#181818",
                        strokeWidth: 1
                    },
                },
                tooltip: { //for hovering over a column
                    renderer: function (params) {
                        let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                        return {
                            title: params.yName,
                            content: content
                        };
                    },
                },
            },
            {
                type: 'bar',
                stacked: true,
                xKey: 'zenGroupId',
                yKey: 'whitelistedIncidentsCount',
                yName: 'Number of Excluded Responses',
                fill: '#4194a5',
                stroke: '#c2c2c2',
                strokeWidth: 1,
                highlightStyle: {
                    item: {
                        fill: "#E8E8E8",
                        stroke: "#181818",
                        strokeWidth: 1
                    },
                },
                tooltip: { //for hovering over a column
                    renderer: function (params) {
                        let content = params.datum.zenGroupName + ": " + params.datum[params.yKey]
                        return {
                            title: params.yName,
                            content: content
                        };
                    },
                },
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                keys: ["zenGroupId"],
                label: {
                    rotation: 50,
                    formatter: function(params){
                        //The params include the zenGroupId (params.value) and the index of the label in the chart data array, so we can access the object and format return value
                        let index = params.index //index of the chart data array
                        if(chartDataWithWhitelistedCount && index !== undefined && index !== null){
                            try{
                                let object = chartDataWithWhitelistedCount[index]
                                if(object){
                                    let name = params.value //initialize to zenGroupId (params.value) in case no zenGroupName found
                                    if(object.zenGroupName){
                                        name = object.zenGroupName
                                    }
                                    if(object.countWithoutWhitelisted !== null && object.countWithoutWhitelisted !== undefined && object.whitelistedIncidentsCount !== null && object.whitelistedIncidentsCount !== undefined){
                                        return `${name} (${object.countWithoutWhitelisted + object.whitelistedIncidentsCount})`
                                    }
                                    else{
                                        //should not get to here but default to return name
                                        return name
                                    }
                                }
                            } catch (e) {}
                        }
                        return params.value //if we get to here then something went wrong, return zenGroupId (params.value)

                    },
                    fontSize: 11
                },
            },
            {
                type: 'number',
                position: 'left',
            },
        ],
        legend: {
            spacing: 40,
            position: 'top',
            enabled: true
        },
    }

    useEffect(() => {
        async function populate() {
            setIsLoading(false);
        }
        populate();
    }, []);

    //using the getGroups above with await since we need these values to populate group names in the chart
    /*useEffect(() => {
        getGroups().then(response => {
            //response is the list of zenGroups
            setZenGroups(response);
        }).catch(function (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error retrieving user's list of groups");
            }
        })
    }, []);*/

    useEffect(() => {
        setChartIsLoading(true)
        //the data returned from this call is for the initial chart and does not include a whitelisted incident count
        incidentsPerGroupCountReactive(false).then(incidentsPerGroupList => {
            setChartIsLoading(false)
            if(incidentsPerGroupList){
                let data = []
                for (let i in incidentsPerGroupList){
                    let group = findZenGroupById(incidentsPerGroupList[i].zenGroupId)
                    if(group && group.friendlyName){
                        //found group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                    else{
                        //else did not find group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                }
                data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                setChartDataWithoutWhitelistedCount(data)
            }
            else{
                setChartDataWithoutWhitelistedCount([])
            }
        }).catch(function (error) {
            setChartIsLoading(false)
        })
    }, [])

    useEffect(() => {
        //the data returned from this call is for when the user toggles to see incident count with whitelisted incidents included
        incidentsPerGroupCountReactive(true).then(incidentsPerGroupList => {
            if(incidentsPerGroupList){
                let data = []
                for (let i in incidentsPerGroupList){
                    let group = findZenGroupById(incidentsPerGroupList[i].zenGroupId)
                    if(group && group.friendlyName){
                        //found group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId, "zenGroupName":group.friendlyName,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                    else{
                        //else did not find group in session
                        data.push({"zenGroupId":incidentsPerGroupList[i].zenGroupId,
                            "countWithoutWhitelisted":incidentsPerGroupList[i].countWithoutWhitelisted,
                            "whitelistedIncidentsCount":incidentsPerGroupList[i].whitelistedIncidentsCount
                        })
                    }
                }
                data.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                setChartDataWithWhitelistedCount(data)
                setIncludeWhitelistedToggleDisabled(false)
            }
            else{
                setChartDataWithWhitelistedCount([])
            }
        }).catch(function (error) {
        })
    }, [])

    //commented out since multiple suggested paths can be returned not just one.
/*    useEffect(async () => {
        if (activeIncident && activeIncident.path && activeIncident.zenGroupId) {
            whitelistPathExists(activeIncident.path, activeIncident.zenGroupId, activeIncident.programArguments).then(result => {
                setIsLoading(false);
                if(!result.userPermitted){
                    setIgnoreButtonDisabled(true)
                }
                else{
                    //setIgnoreButtonDisabled(result.whitelistExists)
                    //just check for permission since things can get tricky with the new incident ignore modal for multiple similar paths
                    setIgnoreButtonDisabled(false)
                }
            }).catch(function (error) {
                setIsLoading(false);
                console.log(error)
                setIgnoreButtonDisabled(true) //if error, disable it
            })
        }
    }, [activeIncident]);*/

    const onIgnoreModalSubmit = async (data) => {
        if(selectedPaths.length < 1){
            NotificationManager.error("You must select at least one path to submit")
        }
        else{
            onWhitelistPath(data)
        }
    };
    const onSilentIncidentCreateModalSubmit = async (data) => {
        if(selectedPaths.length < 1){
            NotificationManager.error("You must select at least one path to submit")
        }
        else{
            onConfirmCreateSilentIncidents()
        }
    };

    const onWhitelistPath = async (data) => {
        try {
            if (clickedIconIncidentData && clickedIconIncidentData.zenGroupId && selectedPaths && selectedPaths.length > 0) {
                let argumentsToSend = null;
                if(whitelistModalIncludeProgramArgs && clickedIconIncidentData.programArgumentsEditedString && clickedIconIncidentData.programArgumentsEditedString.trim().length > 0){
                    argumentsToSend = clickedIconIncidentData.programArgumentsEditedString.trim();
                }
                let parentPathToSend = null
                let parentProgramArgsToSend = null
                if(whitelistModalIncludeParentPath && clickedIconIncidentData.parentPath && clickedIconIncidentData.parentPath.trim().length > 0){
                    parentPathToSend = clickedIconIncidentData.parentPath.trim()
                }
                if(whitelistModalIncludeParentProgramArgs && clickedIconIncidentData.parentProgramArgumentsString && clickedIconIncidentData.parentProgramArgumentsString.trim().length > 0){
                    parentProgramArgsToSend = clickedIconIncidentData.parentProgramArgumentsString.trim()
                }
                let onlyMatchIfTrustedCertToSend = false
                let onlyMatchIfNoInjectionsToSend = false
                let onlyMatchIfNoModifiedMemoryToSend = false
                let fileTriggersSelected = null
                if(additionalRestrictionsToggled){
                    onlyMatchIfTrustedCertToSend = onlyMatchIfTrustedCert
                    onlyMatchIfNoInjectionsToSend = onlyMatchIfNoInjections
                    onlyMatchIfNoModifiedMemoryToSend = onlyMatchIfNoModifiedMemory
                    if(newWhitelistIncidentMatchTypeList && newWhitelistIncidentMatchTypeList.length > 0){
                        fileTriggersSelected = handleFileTriggerTypesSelectedForCreateWhitelist(newWhitelistIncidentMatchTypeList)
                    }
                }
                let tempHoursToSend = 0
                if(temporaryWhitelistToggled){
                    if(data.expirationHoursNewTailoredBehavior < 1){
                        NotificationManager.error("Please Enter the Hours Until Expiration");
                        return;
                    }
                    else{
                        tempHoursToSend = data.expirationHoursNewTailoredBehavior
                    }
                }

                setIsLoading(true);
                let response = await userAddWhitelistsFromIncidentsPageReactive(selectedPaths, clickedIconIncidentData.zenGroupId, argumentsToSend, parentPathToSend, parentProgramArgsToSend,
                    onlyMatchIfTrustedCertToSend, onlyMatchIfNoInjectionsToSend, onlyMatchIfNoModifiedMemoryToSend, fileTriggersSelected, tempHoursToSend)
                if(response.whitelistExistedAlreadyCount > 0){
                    if(selectedPaths.length === 1){
                        //user only tried to make 1 whitelist
                        NotificationManager.info(`This tailored behavior existed already and was not created again`);
                        setIsLoading(false);
                        return
                    }
                    else{
                        //user selected more than one path to make whitelists for
                        NotificationManager.info(`${response.whitelistExistedAlreadyCount} of the tailored behaviors
                        existed already and 
                        ${response.whitelistExistedAlreadyCount > 1 ? "were" : "was"} not created`);
                        setIsLoading(false);
                        return
                    }
                }
                else{
                    NotificationManager.success("Tailored Behavior(s) added successfully");
                }
                reset({expirationHoursNewTailoredBehavior: ""})
                setShowIgnoreModal(false)
                setSuggestedPaths([])
                setSelectedPaths([])
                setIgnoreButtonDisabled(true)
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error adding tailored behavior(s)");
            }
        }
        setIsLoading(false);
        // setShowIgnoreModal(false)
    };

    const onConfirmCreateSilentIncidents =  () => {
        if(clickedIconIncidentData && clickedIconIncidentData.incidentId && selectedPaths && selectedPaths.length > 0){
            let argumentsToSend = null;
            if(clickedIconIncidentData.programArgumentsEditedString){
                argumentsToSend = clickedIconIncidentData.programArgumentsEditedString.trim();
            }
            setIsLoading(true);
            createSilentIncidentsFromIncidentsPageReactive(selectedPaths, clickedIconIncidentData.incidentId, argumentsToSend).then(response => {
                NotificationManager.success("Silent Response(s) successfully created/enabled, you may need to refresh the grid periodically to see updates for any affected responses within the same group");
                setIsLoading(false)
                reset({expirationHoursNewTailoredBehavior: ""})
                setClickedIconIncidentData()
                setShowTurnIncidentSilentModal(false)
                setSuggestedPaths([])
                setSelectedPaths([])
                refreshGrid().then()
            }).catch(function (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error making this request");
                }
                setIsLoading(false)
            })
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Extortion Responses</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Incident Details Modal*/}
            <Modal contentLabel="Response Details" isOpen={openResponseHistoryModal}
                   onRequestClose={() => {
                       setActiveIncident(null);
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setOpenResponseHistoryModal(false);
                       setClickedIconIncidentData()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-6xl max-w-6xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                {/*Cert Publisher Names Modal*/}
                <Modal contentLabel="Certificate Publisher Names" isOpen={showCertificateModal}
                       onRequestClose={() => {
                           setShowCertificateModal(false)
                       }} shouldCloseOnOverlayClick={true}
                       className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                       overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll">

                    <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Certificate Publisher Names</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setShowCertificateModal(false)
                                }}
                            />
                        </div>
                        <hr className="h-0.5" />
                        <div className="">
                            {clickedIconIncidentData && clickedIconIncidentData.certPublisherNames && clickedIconIncidentData.certPublisherNames.length > 0 && clickedIconIncidentData.certPublisherNames.map((certName, i) => {
                                return (
                                    <div key={i} className="mb-3 ml-3 flex flex-row">
                                        {`${certName}`}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal>
                <div className="flex flex-col w-full">
                    <div className="flex flex-row justify-between p-8">
                        <h1 className="font-bold text-3xl">Response Details</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setActiveIncident(null);
                                setSuggestedPaths([])
                                setSelectedPaths([])
                                setOpenResponseHistoryModal(false);
                                setClickedIconIncidentData()
                            }}
                        />
                    </div>
                    <hr className="h-0.5 ml-8 mr-8" />
                    <div className="flex flex-1 flex-col p-8 w-full">
                        <DetailsGrid
                            headers={[
                                { field: "description", headerName: "Description", initialWidth: 50},
                                { field: "value", headerName: "Value"}
                            ]}
                            data={
                                clickedIconIncidentData
                                    ? processData(clickedIconIncidentData, false)
                                    : []
                            }
                        />
                    </div>
                    <div className="mx-8 mb-5">
                        <ThemeProvider theme={buttonTheme}>
                        <Button variant={"contained"} color={"primary"}
                            onClick={() => {
                                setShowCertificateModal(true)
                            }}
                            disabled={!clickedIconIncidentData || !clickedIconIncidentData.certPublisherNames || !clickedIconIncidentData.certPublisherNames.length > 0}
                        >
                            View Certificate Publisher Names
                        </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <InformationModal
                opened={moreInformationModalOpen}
                tailwindModalWith={"max-w-2xl"}
                onClose={() => {
                    setMoreInformationModalOpen(false)
                    setMoreInfoModalText(<div></div>)
                }}
                text={moreInfoModalText}
                contentLabel={"More Info Modal"}
            />
            {/*Ignore Modal*/}
            <Modal contentLabel="Exclude Response"
                   isOpen={showIgnoreModal}
                   onRequestClose={() => {
                       reset({expirationHoursNewTailoredBehavior: ""})
                       setShowIgnoreModal(false)
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setClickedIconIncidentData()
                       setWhitelistModalIncludeProgramArgs(false)
                       setWhitelistModalIncludeParentPath(false)
                       setWhitelistModalIncludeParentProgramArgs(false)
                       setAdditionalRestrictionsToggled(false)
                       setTemporaryWhitelistToggled(false)
                       setOnlyMatchIfTrustedCert(false)
                       setOnlyMatchIfNoInjections(false)
                       setOnlyMatchIfNoModifiedMemory(false)
                       setNewWhitelistIncidentMatchTypeList(fileTriggersToMatchAllOptions)
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-5xl max-w-5xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onIgnoreModalSubmit)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Exclude</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({expirationHoursNewTailoredBehavior: ""})
                                    setShowIgnoreModal(false)
                                    setSuggestedPaths([])
                                    setSelectedPaths([])
                                    setClickedIconIncidentData()
                                    setWhitelistModalIncludeProgramArgs(false)
                                    setWhitelistModalIncludeParentPath(false)
                                    setWhitelistModalIncludeParentProgramArgs(false)
                                    setAdditionalRestrictionsToggled(false)
                                    setTemporaryWhitelistToggled(false)
                                    setOnlyMatchIfTrustedCert(false)
                                    setOnlyMatchIfNoInjections(false)
                                    setOnlyMatchIfNoModifiedMemory(false)
                                    setNewWhitelistIncidentMatchTypeList(fileTriggersToMatchAllOptions)
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="flex flex-1 flex-col w-full">
                            <DetailsGrid
                                headers={[
                                    { field: "description", headerName: "Description"},
                                    { field: "value", headerName: "Value"}
                                ]}
                                data={
                                    clickedIconIncidentData
                                        ? processData(clickedIconIncidentData, true)
                                        : []
                                }
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <div className="flex flex-row">
                                <label>Suggested executable paths. All selected paths will be excluded:</label>
                                {showNoSuggestedPathsFoundIcon &&
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={"No suggested executable paths were found, you may submit this access for review if desired"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                }
                            </div>
                            {suggestedPaths && suggestedPaths.map((path) => {
                                return (
                                    <div key={path} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2 checkbox"
                                            type="checkbox"
                                            onChange={(value) => {
                                                let selectedPathsArray = selectedPaths.slice();
                                                if(selectedPathsArray.includes(path)){
                                                    let index = selectedPathsArray.indexOf(path);
                                                    selectedPathsArray.splice(index, 1);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                                else{
                                                    selectedPathsArray.push(path);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                            }}
                                        />
                                        <label>{path}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex flex-col gap-y-3 ml-1 mt-5 ">
                            <div className={`flex flex-row items-center ml-1`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={whitelistModalIncludeProgramArgs}
                                            disabled={!(clickedIconIncidentData && clickedIconIncidentData.programArgumentsEditedString && clickedIconIncidentData.programArgumentsEditedString.trim().length > 0)}
                                            name="whitelistModalIncludeProgramArgsToggle"
                                            onChange={e => setWhitelistModalIncludeProgramArgs(e.target.checked)}
                                        />
                                    }
                                                      label={whitelistModalIncludeProgramArgs ? "Include Program Arguments in Tailored Behaviors" : "Do Not Include Program Arguments in Tailored Behaviors"}/>
                                </ThemeProvider>
                            </div>
                            <div className={`flex flex-row items-center ml-1`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={whitelistModalIncludeParentPath}
                                            disabled={!(clickedIconIncidentData && clickedIconIncidentData.parentPath && clickedIconIncidentData.parentPath.trim().length > 0)}
                                            name="whitelistModalIncludeParentPathToggle"
                                            onChange={e => setWhitelistModalIncludeParentPath(e.target.checked)}
                                        />
                                    }
                                                      label={whitelistModalIncludeParentPath ? "Include Parent Program Path in Tailored Behaviors" : "Do Not Include Parent Program Path in Tailored Behaviors"}/>
                                </ThemeProvider>
                            </div>
                            <div className={`flex flex-row items-center ml-1`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={whitelistModalIncludeParentProgramArgs}
                                            disabled={!(clickedIconIncidentData && clickedIconIncidentData.parentProgramArgumentsString && clickedIconIncidentData.parentProgramArgumentsString.trim().length > 0)}
                                            name="whitelistModalIncludeParentProgramArgsToggle"
                                            onChange={e => setWhitelistModalIncludeParentProgramArgs(e.target.checked)}
                                        />
                                    }
                                                      label={whitelistModalIncludeParentProgramArgs ? "Include Parent Program Arguments in Tailored Behaviors" : "Do Not Include Parent Program Arguments in Tailored Behaviors"}/>
                                </ThemeProvider>
                            </div>
                            <div className={`flex flex-row items-center ml-1`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={additionalRestrictionsToggled}
                                            name="newWhitelistAdditionalRestrictionsToggled"
                                            onChange={e => setAdditionalRestrictionsToggled(e.target.checked)}
                                        />
                                    }
                                                      label={additionalRestrictionsToggled ? "Enter Additional Tailored Behavior Restrictions" : "Do Not Enter Additional Tailored Behavior Restrictions"}/>
                                </ThemeProvider>
                            </div>
                            <div
                                className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"} flex flex-row items-center`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={onlyMatchIfTrustedCert}
                                            name="newWhitelistOnlyMatchIfTrustedCertToggle"
                                            onChange={e => setOnlyMatchIfTrustedCert(e.target.checked)}
                                        />
                                    }
                                                      label={onlyMatchIfTrustedCert ? "Only Match Tailored Behavior if Trusted Cert" : "Do Not Limit Tailored Behavior to Match Trusted Certs Only"}/>
                                </ThemeProvider>
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain mt-1 cursor-pointer"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={`This setting controls whether the tailored behavior should be applied to an extortion response regardless of if the response has a trusted cert or not, or only apply the 
                                tailored behavior when the extortion response has a trusted cert (meaning the response will be suspended otherwise). The default setting is to not limit.`}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <div
                                className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"} flex flex-row items-center`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={onlyMatchIfNoInjections}
                                            name="newWhitelistOnlyMatchIfNoInjectionsToggle"
                                            onChange={e => setOnlyMatchIfNoInjections(e.target.checked)}
                                        />
                                    }
                                                      label={onlyMatchIfNoInjections ? "Only Match Tailored Behavior if No Related Injections" : "Do Not Limit Tailored Behavior to Match No Related Injections Only"}/>
                                </ThemeProvider>
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain mt-1 cursor-pointer"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={`This setting controls if the tailored behavior should be applied to an extortion response regardless of if the response has related process injections or not, or only apply the
                                tailored behavior when the extortion response has no related process injections (meaning the response will be suspended otherwise). The default setting is to not limit.`}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <div
                                className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"} flex flex-row items-center`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={onlyMatchIfNoModifiedMemory}
                                            name="newWhitelistOnlyMatchIfNoModifiedMemoryToggle"
                                            onChange={e => setOnlyMatchIfNoModifiedMemory(e.target.checked)}
                                        />
                                    }
                                                      label={onlyMatchIfNoModifiedMemory ? "Only Match Tailored Behavior if No Modified Memory" : "Do Not Limit Tailored Behavior to Match No Modified Memory Only"}/>
                                </ThemeProvider>
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain mt-1 cursor-pointer"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={`This setting controls if the tailored behavior should be applied to an extortion response regardless of if the response has modified memory or not, or only apply the
                                tailored behavior when the extortion response has no modified memory (meaning the response will be suspended otherwise). The default setting is to not limit.`}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <div className={`${additionalRestrictionsToggled ? "block ml-3" : "hidden"}`}>
                                <label>File Triggers To Match</label>
                                <MuiAutocompleteNonGroupOptions
                                    options={fileTriggersToMatchAllOptions}
                                    multiple={true}
                                    label={"All Triggers Used by Default"}
                                    value={newWhitelistIncidentMatchTypeList}
                                    onChange={(event, valueList) => {
                                        setNewWhitelistIncidentMatchTypeList(valueList)
                                    }}
                                />
                            </div>
                            <div className={`flex flex-row items-center ml-1`}>
                                <ThemeProvider theme={switchTheme}>
                                    <FormControlLabel control={
                                        <Switch
                                            checked={temporaryWhitelistToggled}
                                            name="addWhitelistFromIncidentsPageTemporaryWhitelistToggled"
                                            onChange={e => setTemporaryWhitelistToggled(e.target.checked)}
                                        />
                                    } label={temporaryWhitelistToggled ? "Make Tailored Behaviors Temporary" : "Make Tailored Behaviors Permanent"}/>
                                </ThemeProvider>
                            </div>
                            <div className={`${temporaryWhitelistToggled ? "block ml-3" : "hidden"}`}>
                                <div className="flex flex-row items-center gap-x-3 gap-y-2 flex-wrap">
                                    <label>Hours Until Expiration</label>
                                    <input
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") {
                                                e.preventDefault();
                                            }
                                        }}
                                        min={0} max={168} type="number"
                                        autoComplete="off"
                                        name="expirationHoursNewTailoredBehavior"
                                        {...register("expirationHoursNewTailoredBehavior")}
                                        className="text-center w-24 focus:outline-none h-10 p-1 rounded-lg border border-black border-opacity-25 border-solid"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ml-1 mt-5 flex flex-row flex-wrap items-center gap-x-3 gap-y-3">
                            <label>Not seeing what you need?</label>
                            <Link to={{pathname: "/private/tailoredBehaviors"}} state={{
                                showCreateModal: true,
                                path: clickedIconIncidentData ? clickedIconIncidentData.path?.trim() : null,
                                parentPath: clickedIconIncidentData ? clickedIconIncidentData.parentPath?.trim() : null,
                                zenGroupId: clickedIconIncidentData ? clickedIconIncidentData.zenGroupId : null,
                                programArguments: clickedIconIncidentData ? clickedIconIncidentData.programArgumentsEditedString?.trim() : null,
                                parentProgramArguments: clickedIconIncidentData ? clickedIconIncidentData.parentProgramArgumentsString?.trim() : null
                            }} className="mr-2">
                                <ThemeProvider theme={roundButtonTheme}>
                                    <Button variant={"contained"} color={"secondary"}>
                                    Take me to Tailored Behaviors Page
                                    </Button>
                                </ThemeProvider>
                            </Link>
                            <ThemeProvider theme={roundButtonTheme}>
                                <Button variant={"contained"} color={"secondary"}
                                        onClick={async () => {
                                            if (clickedIconIncidentData && clickedIconIncidentData.incidentId) {
                                                try {
                                                    setIsLoading(true)
                                                    await submitIncidentForReviewReactive(clickedIconIncidentData.incidentId)
                                                    NotificationManager.success("Successfully submitted this response for review")
                                                    reset({expirationHoursNewTailoredBehavior: ""})
                                                    setShowIgnoreModal(false)
                                                    setSuggestedPaths([])
                                                    setSelectedPaths([])
                                                    setIgnoreButtonDisabled(true)
                                                    setClickedIconIncidentData()
                                                } catch (error) {
                                                    if (error.message) {
                                                        NotificationManager.error(error.message);
                                                    } else {
                                                        NotificationManager.error("Error submitting this response for review")
                                                    }
                                                }
                                                setIsLoading(false)
                                            }
                                        }}
                                >
                                    Submit Response for Review
                                </Button>
                            </ThemeProvider>
                        </div>
                        <div className="flex flex-1 flex-col mt-5">
                        <ThemeProvider theme={buttonTheme}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                                disabled={ignoreSubmitButtonDisabled}
                            >
                                Submit
                            </Button>
                        </ThemeProvider>
                        </div>
                    </div>

                </form>
            </Modal>
            {/*Turn Incident to Silent Modal*/}
            <Modal contentLabel="Silent Response"
                   isOpen={showTurnIncidentSilentModal}
                   onRequestClose={() => {
                       reset({expirationHoursNewTailoredBehavior: ""})
                       setShowTurnIncidentSilentModal(false)
                       setSuggestedPaths([])
                       setSelectedPaths([])
                       setClickedIconIncidentData()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-4xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSilentIncidentCreateModalSubmit)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Silence Response</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({expirationHoursNewTailoredBehavior: ""})
                                    setShowTurnIncidentSilentModal(false)
                                    setSuggestedPaths([])
                                    setSelectedPaths([])
                                    setClickedIconIncidentData()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <div><label>Response Name:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.incidentDisplayName) ? clickedIconIncidentData.incidentDisplayName : ""}`}</div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Program Path:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.path) ? clickedIconIncidentData.path : ""}`}</div>
                        </div>
                        <div className="ml-1 mt-5">
                            <div><label>Program Arguments:</label>{` ${(clickedIconIncidentData && clickedIconIncidentData.programArgumentsEditedString) ? clickedIconIncidentData.programArgumentsEditedString : ""}`}</div>
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <div className="flex flex-row">
                            <label>Suggested executable paths. All selected paths will be silenced:
                            </label>
                                {showNoSuggestedSilentIncidentPathsFoundIcon &&
                                    <MuiIconWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="ml-1 object-contain"
                                                icon="fa-light fa-circle-info"
                                                size="lg"
                                            />
                                        }
                                        tooltipTitle={`No suggested executable paths were found. You may click the "Take me to Silent Responses Page" button that will redirect you to the
                                        Silent Responses page. This will also automatically supply the create new Silent Response form with the response's group, path, and
                                        program arguments (if present).`}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                }
                            </div>
                            {suggestedPaths && suggestedPaths.map((path) => {
                                return (
                                    <div key={path} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2 checkbox"
                                            type="checkbox"
                                            onChange={(value) => {
                                                let selectedPathsArray = selectedPaths.slice();
                                                if(selectedPathsArray.includes(path)){
                                                    let index = selectedPathsArray.indexOf(path);
                                                    selectedPathsArray.splice(index, 1);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                                else{
                                                    selectedPathsArray.push(path);
                                                    setSelectedPaths(selectedPathsArray);
                                                }
                                            }}
                                        />
                                        <label>{path}</label>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ml-1 mt-5 flex flex-row flex-wrap items-center gap-x-3 gap-y-3">
                            <label>Not seeing what you need?</label>
                            <div className="mt-2">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={"Clicking this button will redirect you to the Silent Responses page and automatically supply the create new Silent\n" +
                                        "                                                    Response form with the response's group, path, and program arguments (if present)."}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                            <Link to={{pathname:"/private/silentResponses"}} state={{showCreateModal: true,
                                path: clickedIconIncidentData ? clickedIconIncidentData.path?.trim() : null,
                                zenGroupId: clickedIconIncidentData ? clickedIconIncidentData.zenGroupId : null,
                                programArguments: clickedIconIncidentData ? clickedIconIncidentData.programArgumentsEditedString?.trim() : null
                            }} className="mr-2">
                                <ThemeProvider theme={roundButtonTheme}>
                                    <Button variant={"contained"} color={"secondary"}>
                                        Take me to Silent Responses Page
                                    </Button>
                                </ThemeProvider>
                            </Link>
                        </div>
                        <div className="flex flex-1 flex-col mt-5">
                            <ThemeProvider theme={buttonTheme}>
                                <Button variant={"contained"} color={"primary"}
                                        type={"submit"}
                                    disabled={silentIncidentTurnOnModalButtonDisabled}
                                >
                                Submit
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>

                </form>
            </Modal>
            <BrowserUtilityProcessTuningModal tuningModalIsOpen={tuningModalIsOpen} setTuningModalIsOpen={setTuningModalIsOpen} setIsLoading={setIsLoading} initialZenGroup={zenGroupForTuningModal} />
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col ml-5 mr-10 mt-8 flex-nowrap gap-y-3 h-full">
                    {privatePageHeaderHelper("Extortion Responses")}
                    <hr className="bg-black h-0.5" />
                    <div className={'flex flex-col gap-y-0 flex-nowrap'}>
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={chartToggled}
                                        name="incidentsPerGroupToggle"
                                        onChange={e => {
                                            setChartToggled(e.target.checked)
                                            updateChartVisibilitySettingInSession(chartSessionVariableName, e.target.checked, updateDataIncidentChartVisibilityReactive)
                                        }}
                                    />
                                } label={chartToggled ? "Showing Metrics Chart" : "Hiding Metrics Chart"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${chartToggled ? 'block' : 'hidden'} flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        disabled={includeWhitelistedToggleDisabled}
                                        checked={includeWhitelistedToggled}
                                        name="includeWhitelistedIncidentsToggle"
                                        onChange={e => setIncludeWhitelistedToggled(e.target.checked)}
                                    />
                                } label = {includeWhitelistedToggled ? "Showing Excluded Responses" : "Hiding Excluded Responses"}/>
                            </ThemeProvider>
                        </div>
                    </div>
                    {/*Incidents Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block` : `hidden`} relative`}>
                        <AgChartsReact options={
                            !includeWhitelistedToggled ? chartWithoutWhitelistedCountOptions : chartWithWhitelistedCountOptions
                        } />
                        <BackDropChartLoadingOverlay opened={chartIsLoading} />
                    </div>
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end items-center"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateIncidentsGridColumnModeReactive} />
                            <div className={"mr-2"}>
                                <ClearRefresh gridApi = {gridApi}
                                              refreshGridFunction = {refreshGrid} showRefreshIcon={false}/>
                            </div>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'incidentsGridFilterState', updateIncidentsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateIncidentsGridUseColumnStateReactive);
    }

    function initializeWhitelistModalForIconClick(incidentData){
        setSelectedPaths([])
        setClickedIconIncidentData(incidentData)
        setIgnoreSubmitButtonDisabled(false)
        setShowNoSuggestedPathsFoundIcon(false)
        setWhitelistModalIncludeProgramArgs(false)
        setWhitelistModalIncludeParentPath(false)
        setWhitelistModalIncludeParentProgramArgs(false)
        setNewWhitelistIncidentMatchTypeList(fileTriggersToMatchAllOptions)
        setAdditionalRestrictionsToggled(false)
        setTemporaryWhitelistToggled(false)
        setOnlyMatchIfTrustedCert(false)
        setOnlyMatchIfNoInjections(false)
        setOnlyMatchIfNoModifiedMemory(false)
        //load suggested paths and programArgs
        findSuggestedPathsReactive(incidentData?.path).then(response => {
            if(response.suggestedPaths && response.suggestedPaths.length > 0){
                setSuggestedPaths(response.suggestedPaths)
            }
            else{
                setSuggestedPaths([])
                setIgnoreSubmitButtonDisabled(true)
                setShowNoSuggestedPathsFoundIcon(true)
            }
        }).catch(function (error) {
            setSuggestedPaths([])
            setIgnoreSubmitButtonDisabled(true)
            setShowNoSuggestedPathsFoundIcon(true)
        })
        setShowIgnoreModal(true)
    }

    function initializeDetailsModalForIconClick(incidentData){
        setClickedIconIncidentData(incidentData)
        setOpenResponseHistoryModal(true)
    }

    function initializeTurnSilentSettingToOffForIconClick(incidentData){
        //setClickedIconIncidentData(incidentData)
        if(incidentData && incidentData.incidentId){
            userSetIncidentAsNotSilentReactive(incidentData.incidentId).then(response => {
                NotificationManager.success("Successfully queued update, you may need to refresh the grid periodically to see updates for any affected responses within the same group")
                setClickedIconIncidentData()
                refreshGrid().then()
            }).catch(function (error) {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error completing this request.");
                }
            })
        }
    }

    function initializeTurnSilentSettingToOnForIconClick(incidentData){
        //mirroring this program arguments replacement, don't think we need to do this though
        let data = incidentData
        let programArgumentsString = ""
        let parentProgramArgumentsString = ""
        if(data) {
            programArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.path, data.programArguments)
            parentProgramArgumentsString = removePathFromStartOfProgramArgumentsHelper(data.parentPath, data.parentProgramArguments)
        }

        data.programArgumentsEditedString = programArgumentsString
        data.parentProgramArgumentsString = parentProgramArgumentsString
        setClickedIconIncidentData(data)
        setShowNoSuggestedSilentIncidentPathsFoundIcon(false)
        setSilentIncidentTurnOnModalButtonDisabled(false)
        setSelectedPaths([])
        findSuggestedSilentIncidentPathsReactive(incidentData.path, incidentData.incidentId).then(suggestedPaths => {
            if(suggestedPaths && suggestedPaths.length > 0){
                setSuggestedPaths(suggestedPaths)
            }
            else{
                setSuggestedPaths([])
                setSilentIncidentTurnOnModalButtonDisabled(true)
                setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
            }
        }).catch(function (error) {
            setSuggestedPaths([])
            setSilentIncidentTurnOnModalButtonDisabled(true)
            setShowNoSuggestedSilentIncidentPathsFoundIcon(true)
        })
        setShowTurnIncidentSilentModal(true)
    }

    function getGrid(){
        return (
            <Grid
                onClickRow={(data) => {
                    //setActiveIncident(data[0])
                }}
                initialGridColumnDefs={initialGridColumnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                detailRowHeight={detailRowHeight}
                setDetailRowHeight={setDetailRowHeight}
                setGridApi={setGridApi}
                initializeWhitelistModalForIconClick={initializeWhitelistModalForIconClick}
                initializeDetailsModalForIconClick={initializeDetailsModalForIconClick}
                initializeTurnSilentSettingToOnForIconClick={initializeTurnSilentSettingToOnForIconClick}
                initializeTurnSilentSettingToOffForIconClick={initializeTurnSilentSettingToOffForIconClick}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                incidentLocation={incidentLocation}
            />
        );
    }

    async function refreshGrid(){
        //TODO: figure out what to do here for refreshing
        return
        /*
        await refreshGridZenGroupAndAgentInformation(gridApi, "incidentId", "id", findByIncidentIdListReactive,
            keysToRefresh, "agentId",null, true)
        gridApi && gridApi.refreshCells({columns: ["hidingRule"], suppressFlash: true, force: true})

         */
    }
    /*
    function resetGrid(){
        ReactDOM.unmountComponentAtNode(document.getElementById("gridRoot"))
        ReactDOM.render(getGrid(), document.getElementById('gridRoot'));
        setOpenResponseHistoryModal(false)
        setActiveIncident(null);
        setSuggestedPaths([])
        setSelectedPaths([])
        setIgnoreButtonDisabled(true)
        setIsLoading(false);
        setShowIgnoreModal(false)
    }

     */

    function checkChromeProgramArgumentsForTuningModal(programArgumentsDecoded){
        let showTuningModal = false
        if(programArgumentsDecoded !== null && programArgumentsDecoded !== undefined && programArgumentsDecoded.trim().length > 0){
            chromeUtilityArgsToShowTuningModal.forEach(args => {
                if(programArgumentsDecoded.toLowerCase().includes(args.toLowerCase())){
                    showTuningModal = true
                }
            })
        }
        return showTuningModal
    }


}

let saveFilterChanges = true //If user clicks link from data incident notification and is redirected to data incidents page, we don't want to save filters
class Grid extends Component {
    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        //params.api.sizeColumnsToFit();
    };
    onCellValueChanged = (event) => {
        //console.log("cell change old value: "+event.oldValue+" and new value "+event.newValue);
    }
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateIncidentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateIncidentsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateIncidentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateIncidentsGridColumnModeReactive, customColumnModeText)
        }
    }
    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };
    onGridReady = async (gridReadyParams) => {
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
        gridReadyParams.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        //check if we want to apply saved column state

        //Only max/custom are allowed for this grid
        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnStateForIncidentsGrid(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateIncidentsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateIncidentsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        let filterForNotification = false
        saveFilterChanges = true //reset to true
        if(this.props.incidentLocation && this.props.incidentLocation.state){
            let loginURLSearchParams = this.props.incidentLocation.state.loginURLSearchParams
            let fullDataIncidentFilterCriteriaURLSearchParamsEncoded = this.props.incidentLocation.state.fullDataIncidentFilterCriteria
            if(loginURLSearchParams !== null && loginURLSearchParams !== undefined && loginURLSearchParams.trim().length > 0){
                //call to get the notification with loginURLSearchParams
                try{
                    let response = await findNotificationEventForDataIncidentAutoFilter(loginURLSearchParams)

                    let zenGroupId = response.zenGroupId
                    let zenGroup = findZenGroupById(zenGroupId)
                    let queryStartDateMillis = response.queryStartDateMillis
                    let queryEndDateMillis = response.queryEndDateMillis
                    let sendAlertsForSilentIncidents = response.sendAlertsForSilentIncidents
                    if(zenGroupId && queryStartDateMillis && queryEndDateMillis && zenGroup && zenGroup.friendlyName){
                        let startFilterString = getDateStringForAgGridFilter(queryStartDateMillis)
                        let endFilterString = getDateStringForAgGridFilter(queryEndDateMillis)
                        if(startFilterString && endFilterString){
                            //build filter to apply to grid
                            filterForNotification = true
                            saveFilterChanges = false //don't save filter changes for when we auto filter
                            let filter = {}
                            filter["zenGroupDisplayName"] = {
                                "values": [
                                    zenGroup.friendlyName
                                ],
                                "filterType": "set"
                            }
                            filter["dateCreatedRounded"] = {
                                "dateFrom": startFilterString,
                                "dateTo": endFilterString,
                                "filterType": "date",
                                "type": "inRange"
                            }
                            //Don't filter for non-silent only because at time of notification sendAlertsForSilentIncidents could be false and then after the user silences incident, which means the incident will
                            // not appear if we include the filter below
                            /*let silentFilterObject = {}
                            if(!sendAlertsForSilentIncidents){
                                //if sendAlertsForSilentIncidents is false then we want to apply the default silent filter like we would normally
                                silentFilterObject = {
                                    "values": [
                                        "Not Silent"
                                    ],
                                    "filterType": "set"
                                }
                            }*/
                            filter["whitelistAppliedId"] = {
                                "filterType": "multi",
                                "filterModels": [
                                    {
                                        "values": [
                                            "Not Excluded"
                                        ],
                                        "filterType": "set"
                                    }
                                ]
                            }
                            //apply filter model
                            gridReadyParams.api.setFilterModel(filter);
                        }
                    }
                } catch (e) {}
            }
            else if(fullDataIncidentFilterCriteriaURLSearchParamsEncoded !== null && fullDataIncidentFilterCriteriaURLSearchParamsEncoded !== undefined && fullDataIncidentFilterCriteriaURLSearchParamsEncoded.trim().length > 0){
                try{
                    //We are coming from SIEM tool (Sentinel), the decoded url param will be an object with each key containing the encoded filter value
                    let fullDataIncidentFilterCriteriaURLSearchParams = JSON.parse(atob(fullDataIncidentFilterCriteriaURLSearchParamsEncoded))
                    let groupIdEncoded = fullDataIncidentFilterCriteriaURLSearchParams.groupIdEncoded
                    let machineEncoded = fullDataIncidentFilterCriteriaURLSearchParams.machineEncoded
                    let dateCreatedRoundedEncoded = fullDataIncidentFilterCriteriaURLSearchParams.dateCreatedRoundedEncoded
                    if(groupIdEncoded && machineEncoded && dateCreatedRoundedEncoded){
                        //At the very least, we need these fields above to continue with filter
                        let zenGroupId = atob(groupIdEncoded)
                        let zenGroup = findZenGroupById(zenGroupId)
                        let dateCreatedRoundedDecodedString = atob(dateCreatedRoundedEncoded)
                        let rangeDatesObject = getAgGridDateRangeFilterForIncidentDateCreatedRoundedInterval(dateCreatedRoundedDecodedString)
                        if(zenGroupId && rangeDatesObject && zenGroup && zenGroup.friendlyName){
                            let startDateFilterString = rangeDatesObject.startDate
                            let endDateFilterString = rangeDatesObject.endDate
                            if(startDateFilterString && endDateFilterString){
                                let pathEncoded = fullDataIncidentFilterCriteriaURLSearchParams.pathEncoded
                                let parentPathEncoded = fullDataIncidentFilterCriteriaURLSearchParams.parentPathEncoded
                                let machineName = atob(machineEncoded)
                                //build filter to apply to grid
                                filterForNotification = true
                                saveFilterChanges = false //don't save filter changes for when we auto filter
                                let filter = {}
                                filter["zenGroupDisplayName"] = {
                                    "values": [
                                        zenGroup.friendlyName
                                    ],
                                    "filterType": "set"
                                }
                                filter["dateCreatedRounded"] = {
                                    "dateFrom": startDateFilterString,
                                    "dateTo": endDateFilterString,
                                    "filterType": "date",
                                    "type": "inRange"
                                }
                                filter["machineName"] = {
                                    "filterType": "text",
                                    "type": "contains",
                                    "filter": machineName
                                }
                                if(pathEncoded){
                                    filter["pathEncoded"] = {
                                        "filterType": "text",
                                        "type": "contains",
                                        "filter": atob(pathEncoded)
                                    }
                                }
                                if(parentPathEncoded){
                                    filter["parentPathEncoded"] = {
                                        "filterType": "text",
                                        "type": "contains",
                                        "filter": atob(parentPathEncoded)
                                    }
                                }

                                //TODO: Take in a silent incident param in URL or just always filter for not silent incidents??
                                filter["whitelistAppliedId"] = {
                                    "filterType": "multi",
                                    "filterModels": [
                                        {"values": ["Not Excluded"], "filterType": "set"},
                                        {"values":["Not Silent"],"filterType":"set"}
                                    ]
                                }
                                //apply filter model
                                gridReadyParams.api.setFilterModel(filter);
                            }
                        }
                    }
                } catch (e) {}
            }
        }

        //the indexes are in the same order as they are in the column def for whitelistAppliedId, so whitelisted is first and silent is second. Default to show Not Whitelisted and Not Silent incidents
        if(!filterForNotification){
            let defaultFilterObject = {"whitelistAppliedId":{"filterType":"multi","filterModels":[{"values":["Not Excluded"],"filterType":"set"},{"values":["Not Silent"],"filterType":"set"}]}}
            onGridReadyHelper(gridReadyParams, "incidentsGridFilterState",defaultFilterObject);
        }


        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);


        var dataSource = {
            getRows: async function (params) {
                try{
                    let start = params.request.startRow;
                    let end = params.request.endRow;
                    let count = end - start;
                    let page = Math.floor(start / count)
                    let filters = masterFilterHelper(params.request.filterModel)
                    let sortModel = params.request.sortModel;
                    let sortModelForRequest = null;
                    if(sortModel && sortModel.length > 0){
                        try{ //extra precaution for array access on the sort model so it does not break
                            if(sortModel[0].colId && sortModel[0].sort){
                                sortModelForRequest = sortModel[0]
                            }
                        }
                        catch(error){
                            console.log("Error generating sort request")
                        }
                    }
                    let incidents = await incidentTimeSeriesListReactive(count, page, sortModelForRequest, filters)
                    let finalPage = true
                    //This logic mimics how we would check in the rest api for what to send for the finalPage in the response. Same logic is being used here so we can just return a flux from the
                    // endpoint instead of Mono with a Response object
                    if(incidents && incidents.length === count){
                        finalPage = false
                    }
                    let lastIdx = undefined
                    if (finalPage) {
                        lastIdx = (count * page) + incidents.length
                    }

                    params.api.deselectAll();

                    const success = (incidents.length !== 0);
                    if (success) {
                        params.api.hideOverlay()
                        params.success({
                            rowData: incidents, rowCount: lastIdx
                        })
                    } else {
                        //params.api.showNoRowsOverlay();
                        params.success({
                            rowData: incidents, rowCount: lastIdx
                        })
                        params.fail();
                    }
                }
                catch(error){
                    //console.log(error);
                    params.api.showNoRowsOverlay();
                    params.fail();
                }
            }
        }
        gridReadyParams.api.setGridOption("serverSideDatasource", dataSource);
        //gridReadyParams.api.sizeColumnsToFit()
    };

    detailCellRendererParams = (detailParams) => {

        let agentIdOfMaster = detailParams.node.data.agentId
        let agentNameOfMaster = detailParams.node.data.agentDisplayName
        //let machineNameForLocationLinkOfMaster = detailParams.node.data.machineNameForLocationLink
        let detailGridColumnDefs = [
            {
                field: "incidentDisplayName",
                headerName: `Response Name`,
                sortable: true,
                width: 415,
                editable: true,
                cellEditor: "customNameCellEditor",
                filter: 'agTextColumnFilter',
                filterParams: {
                    suppressSorting: true,
                    buttons: ["reset", "apply"],

                    filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                    maxNumConditions: 1,
                },
                valueFormatter: null,
                cellRenderer: (params) => {
                    return incidentNameCellRendererFrameworkForIncidentsGrid(params, this.props.initializeWhitelistModalForIconClick, this.props.initializeDetailsModalForIconClick,
                        this.props.initializeTurnSilentSettingToOnForIconClick, this.props.initializeTurnSilentSettingToOffForIconClick, "Response")
                }
            },
            { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
            {
                field: "rootCauseAnalysis", headerName: "Root Cause Analysis", width: 300,
                sortable: false,
                valueFormatter: function (params) {
                    if (params.node.data.rootCauseAnalysis) {
                        return params.node.data.rootCauseAnalysis
                    }
                    let path = params.node.data.path
                    if (path && params.node.data.incidentId && params.node.data.zenGroupId && params.node.data.uniqueProcessIdentifier !== null && params.node.data.uniqueProcessIdentifier !== undefined) {
                        checkIncidentHasRelatedProcessInjectionsAndCreationsReactive(params.node.data.incidentId, params.node.data.zenGroupId, params.node.data.uniqueProcessIdentifier, path).then(response => {
                            params.node.data.relatedProcessCreationExists = response.relatedProcessCreationExists
                            params.node.data.relatedProcessInjectionExists = response.relatedProcessInjectionExists
                            params.node.data.memoryFileExists = response.memoryFileExists
                            //Show the Related Processes Found text over Memory Download Available if either fields are true
                            if (response.relatedProcessCreationExists || response.relatedProcessInjectionExists) {
                                params.node.setDataValue("rootCauseAnalysis", "Related Processes Found")
                            } else {
                                //If we have memoryFileExists true then we can show some text, else show nothing
                                if(response.memoryFileExists){
                                    params.node.setDataValue("rootCauseAnalysis", "Memory Diff Download Available")
                                }
                                else{
                                    params.node.setDataValue("rootCauseAnalysis", " ") //lets cell know not to call checkIncidentHasRelatedProcessInjectionsAndCreationsReactive again
                                }
                            }
                        }).catch(function (error) {
                            params.node.setDataValue("rootCauseAnalysis", " ") //lets cell know not to call checkIncidentHasRelatedProcessInjectionsAndCreationsReactive again
                        })
                        params.node.data.rootCauseAnalysis = params.node.data.zenGroupId
                        return params.node.data.zenGroupId
                    }
                },
                cellRenderer: function (params) {
                    let spinnerDiv = ""
                    let memoryFileDownloadIcon = ""
                    let processCreationsLinkDiv = ""
                    let processInjectionsLinkDiv = ""
                    let path = params.node.data.path
                    if (path && params.node.data.zenGroupId && params.node.data.uniqueProcessIdentifier !== null && params.node.data.uniqueProcessIdentifier !== undefined) {
                        if (params.node.data.rootCauseAnalysis === params.node.data.zenGroupId) {
                            spinnerDiv = <FontAwesomeIcon
                                className="contain fa-pulse mr-1"
                                icon="fa-light fa-spinner"
                                size="lg"
                                name="relatedProcessesLoading"
                            />
                        } else {
                            spinnerDiv = ""
                        }
                        //Check if we should show Memory file download icon
                        if (params.node.data.memoryFileExists === true) {
                            memoryFileDownloadIcon =
                                <div className={"mb-1"}>
                                    <MuiIconButtonWithTooltip
                                        icon={ <FontAwesomeIcon
                                            className="object-contain"
                                            icon="fa-duotone fa-download"
                                            size="xs"
                                        />}
                                        tooltipTitle={"Click to download the Memory Diff File for this Extortion Response. Please contact support for more information about this file."}
                                        tooltipPlacement={"bottom-start"}
                                        onClick={() => {
                                            if(params.node.data.incidentId){
                                                getCCDiffFileReactive(params.node.data.incidentId).then(response => {
                                                    try{
                                                        let incidentName = params.node.data.incidentDisplayName
                                                        let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                        let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                        //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                        let today = new Date();
                                                        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                                        let time = today.getHours() + "-" + today.getMinutes();
                                                        let dateTime = date+'T'+time;
                                                        let link=document.createElement('a');
                                                        link.href=window.URL.createObjectURL(blob);
                                                        link.download=`${agentNameOfMaster}-${incidentName}-${dateTime}.ccdiff`;
                                                        link.click();
                                                    }
                                                    catch(error){
                                                        NotificationManager.error("Unexpected error making this request");
                                                    }
                                                }).catch(function (error) {
                                                    if(error.message){
                                                        NotificationManager.error(error.message);
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error making this request");
                                                    }
                                                })
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error making this request");
                                            }
                                        }}
                                    />
                                </div>
                        }

                        //Links
                        if (params.node.data.relatedProcessCreationExists === true) {
                            processCreationsLinkDiv =
                                <Link to={{
                                    pathname: "/private/processCreations"
                                }} state={{
                                    uniqueProcessIdentifier: params.node.data.uniqueProcessIdentifier,
                                    zenGroupIdClicked: params.node.data.zenGroupId,
                                    childPath: path,
                                    agentIdClicked: params.node.data.agentId
                                }} className="">
                                    <div className={"mb-1"}>
                                        <MuiIconButtonWithTooltip
                                        icon={
                                            <FontAwesomeIcon
                                                className="object-contain"
                                                icon="fa-duotone fa-magnifying-glass-arrow-right"
                                                size="xs"
                                            />
                                        }
                                        tooltipTitle={"Click to view related process creations"}
                                        tooltipPlacement={"bottom-start"}
                                    />
                                    </div>
                                </Link>
                        }
                        if (params.node.data.relatedProcessInjectionExists === true) {
                            processInjectionsLinkDiv =
                                <Link to={{
                                    pathname: "/private/processInjections"
                                }} state={{
                                    uniqueProcessIdentifier: params.node.data.uniqueProcessIdentifier,
                                    zenGroupIdClicked: params.node.data.zenGroupId,
                                    agentIdClicked: params.node.data.agentId
                                }} className="">
                                    <div className={"mb-1"}>
                                        <MuiIconButtonWithTooltip
                                            icon={
                                                <FontAwesomeIcon
                                                    className="object-contain"
                                                    icon="fa-duotone fa-syringe"
                                                    size="xs"
                                                />
                                            }
                                            tooltipTitle={"Click to view related process injections"}
                                            tooltipPlacement={"bottom-start"}
                                        />
                                    </div>
                                </Link>
                        }
                    }

                    return (
                        <div className={"flex flex-nowrap items-center justify-start gap-x-0"}>
                            {spinnerDiv}
                            {memoryFileDownloadIcon}
                            {processCreationsLinkDiv}
                            {processInjectionsLinkDiv}
                            {params.node.data.rootCauseAnalysis}
                        </div>
                    )
                }
            },
            { field: "status", headerName: "Status", width: 275,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: function(params){
                        if(params.value === "WHITELISTED"){
                            return "EXCLUDED"
                        }
                        else{
                            return params.value
                        }
                    },
                    showTooltips: true
                },
                valueFormatter: function(params){
                    if(params.value === "WHITELISTED"){
                        return "EXCLUDED"
                    }
                    else{
                        return params.value
                    }
                },
                sortable: true
            },
            {
                //field that is set by us for getting either whitelist name or silent incident/response name(s)
                field: "hidingRule", headerName: "Hiding Rule", width : 430, sortable: false,
                valueFormatter: function(params){
                    if(params.node.data.hidingRule){
                        return params.node.data.hidingRule
                    }
                    if(params.node.data.whitelistAppliedId){
                        //call for whitelist name that is associated with whitelistAppliedId
                        let whitelistId = params.node.data.whitelistAppliedId
                        findBySingleWhitelistAppliedIdReactive(whitelistId).then(function(response){
                            if(response.whitelistName !== null){
                                params.node.setDataValue("hidingRule", response.whitelistName)
                            }
                            else{
                                params.node.setDataValue("hidingRule", "(The tailored behavior applied at the time of response was deleted)")
                            }
                            /*if(response.whitelist !== null){
                                params.node.data.tooltipData = response.whitelist
                            }*/
                        }).catch(function (error) {
                            params.node.setDataValue("hidingRule", " ")
                        })
                        params.node.data.hidingRule = whitelistId
                    }
                    else{
                        //else if incident was not whitelisted then check the silent field
                        if(params.node.data.silent){
                            let incidentId = params.node.data.incidentId
                            findSilentIncidentNamesForSingleIncidentIdReactive(incidentId).then(function(silentIncidentsList){
                                let listString = " "
                                let namesSet = new Set();
                                if(silentIncidentsList && silentIncidentsList.length > 0){
                                    silentIncidentsList.forEach(silentIncident => {
                                        if(silentIncident.silentIncidentDisplayName){
                                            namesSet.add(silentIncident.silentIncidentDisplayName)
                                        }
                                    })
                                    //convert set to array and join values together into one string to show in cell
                                    listString = Array.from(namesSet).join(' | ')
                                    //listString = Array.from(namesSet).join(', ')
                                }
                                params.node.setDataValue("hidingRule", listString)
                            }).catch(function (error) {
                                params.node.setDataValue("hidingRule", " ")
                            })
                            params.node.data.hidingRule = incidentId
                        }
                        else{
                            //don't show anything for incidents that aren't silent, but need to set dataValue so the spinner will stop in the cell renderer
                            params.node.setDataValue("hidingRule", " ")
                        }
                    }
                    return null
                },
                cellRenderer: function(params){
                    let spinnerDiv = ""
                    if(params.node.data.hidingRule === null || params.node.data.hidingRule === undefined || params.node.data.hidingRule === params.node.data.whitelistAppliedId ||
                        params.node.data.hidingRule === params.node.data.incidentId){
                        spinnerDiv =
                            <div>
                                <FontAwesomeIcon
                                    className="contain fa-pulse mr-1"
                                    icon="fa-light fa-spinner"
                                    size="lg"
                                    name="hidingRuleSpinner"
                                />
                                Loading
                            </div>
                    }
                    return(
                        <div className={"flex flex-nowrap items-center gap-x-2"}>
                            {spinnerDiv}
                            {params.node.data.hidingRule}
                        </div>
                    )
                },
            },
            { field: "incidentType", headerName: "File Access Trigger", width: 250,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: (params) => {
                        if(params.value!=="(Select All)"){
                            return params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase();
                        }
                        else{
                            return params.value;
                        }
                    },
                    //File Attributes is for both SET_INFO and TIMESTAMP_OR_LEGAL_ATTRS, backend accounts for this in filter for this incidentType field
                    values: ["DELETE", "READ", "RENAME", fileAttributesString, "WRITE"],
                },
                sortable: true,
                valueFormatter:
                    function(params){
                        if(params.value) {
                            //if the incidentType is TIMESTAMP_OR_LEGAL_ATTRS or SET_INFO, return "File Attributes" for a better customer facing value, else return the value with first letter capitalized
                            switch(params.value) {
                                case "TIMESTAMP_OR_LEGAL_ATTRS":
                                    return fileAttributesString
                                case "SET_INFO":
                                    return fileAttributesString
                                default:
                                    //return first letter capitalized
                                    return params.value.charAt(0).toUpperCase() + params.value.slice(1).toLowerCase();
                            }
                        }
                    },
            },
            { field: "hasUntrustedRemoteThreads", headerName: "Untrusted Remote Threads", width: 325,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: (params) => {
                        if(params.value!=="(Select All)"){
                            switch(params.value) {
                                case "UNKNOWN":
                                    return "Unknown"
                                case "TRUE":
                                    return "True"
                                case "FALSE":
                                    return "False"
                                default:
                                    return "";
                            }
                        }
                        else{
                            return params.value;
                        }
                    },
                    values: ['FALSE', 'TRUE', 'UNKNOWN'],
                    suppressSorting: true,
                },
                valueFormatter:
                    function (params) {
                        if(params.node.data.hasUntrustedRemoteThreads){
                            switch(params.node.data.hasUntrustedRemoteThreads) {
                                case "UNKNOWN":
                                    return "Unknown"
                                case "TRUE":
                                    return "True"
                                case "FALSE":
                                    return "False"
                                default:
                                    return "";
                            }
                        }else{
                            return ""
                        }
                    },
                sortable: true
            },
            { field: "hasModifiedMem", headerName: "Modified Memory", width: 230,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: (params) => {
                        if(params.value!=="(Select All)"){
                            switch(params.value) {
                                case "UNKNOWN":
                                    return "Unknown"
                                case "TRUE":
                                    return "True"
                                case "FALSE":
                                    return "False"
                                default:
                                    return "";
                            }
                        }
                        else{
                            return params.value;
                        }
                    },
                    values: ['FALSE', 'TRUE', 'UNKNOWN'],
                    suppressSorting: true,
                },
                valueFormatter:
                    function (params) {
                        if(params.node.data.hasModifiedMem){
                            switch(params.node.data.hasModifiedMem) {
                                case "UNKNOWN":
                                    return "Unknown"
                                case "TRUE":
                                    return "True"
                                case "FALSE":
                                    return "False"
                                default:
                                    return "";
                            }
                        }else{
                            return ""
                        }
                    },
                sortable: true
            },
            { field: "hasNoTrustedCert", headerName: "No Trusted Cert", width: 230,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: (params) => {
                        if(params.value!=="(Select All)"){
                            switch(params.value) {
                                case "UNKNOWN":
                                    return "Unknown"
                                case "TRUE":
                                    return "True"
                                case "FALSE":
                                    return "False"
                                default:
                                    return "";
                            }
                        }
                        else{
                            return params.value;
                        }
                    },
                    values: ['FALSE', 'TRUE', 'UNKNOWN'],
                    suppressSorting: true,
                },
                valueFormatter:
                    function (params) {
                        if(params.node.data.hasNoTrustedCert){
                            switch(params.node.data.hasNoTrustedCert) {
                                case "UNKNOWN":
                                    return "Unknown"
                                case "TRUE":
                                    return "True"
                                case "FALSE":
                                    return "False"
                                default:
                                    return "";
                            }
                        }else{
                            return ""
                        }
                    },
                cellRenderer: function(params){
                    let iconDiv = ""
                    if(params.node.data.windowsCode === 258){ //Timeout
                        iconDiv = <MuiIconButtonWithTooltip
                            icon={<TimerOffOutlinedIcon className={"cursor-pointer"}/>}
                            tooltipTitle={`Timeout occurred retrieving cert information from Windows`}
                            tooltipPlacement={"bottom-start"}
                        />
                    }
                    else if(params.node.data.windowsCode === 3221225527){ //Disconnect
                        iconDiv = <MuiIconButtonWithTooltip
                            icon={<VpnKeyOffOutlinedIcon className={"cursor-pointer"}/>}
                            tooltipTitle={`Disconnect occurred retrieving cert information from Windows. Reconnection was automatically restarted`}
                            tooltipPlacement={"bottom-start"}
                        />
                    }
                    return(
                        <div className={"flex flex-nowrap items-center gap-x-2"}>
                            {iconDiv}
                            {params.valueFormatted}
                        </div>
                    )
                },
                sortable: true
            },
            { field: "certPublisherNames", headerName: "Cert Publisher Names List", hide: true, sortable: true, width: 500, suppressColumnsToolPanel: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    suppressSorting: true,
                    buttons: ["reset", "apply"],

                    filterOptions: ['contains'],
                    maxNumConditions: 1,
                }
            },
            { field: "agentVersionFormatted", headerName: "Agent Version", width: 225,
                filter: 'agTextColumnFilter',
                filterParams: {
                    suppressSorting: true,
                    buttons: ["reset", "apply"],

                    filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                    maxNumConditions: 1,
                },
                sortable: true,
                valueFormatter: agentVersionFormatter,
                cellRenderer: function(params){
                    let spinnerDiv = ""
                    if(params.node.data.agentId === params.valueFormatted ){
                        spinnerDiv = <FontAwesomeIcon
                            className="contain fa-pulse"
                            icon="fa-light fa-spinner"
                            size="lg"
                            name="agentVersionLoading"
                        />
                    }else{
                        spinnerDiv = ""
                    }
                    return(
                        <div id ="fortooltip" className={"flex flex-nowrap items-center gap-x-2"}>
                            {spinnerDiv}
                            {params.valueFormatted}
                        </div>
                    )
                },


            },
            { field: "currentLatestAgentVersion", headerName: "Latest Agent Version", width: 275,
                filter: 'agTextColumnFilter',
                filterParams: {
                    suppressSorting: true,
                    buttons: ["reset", "apply"],

                    filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
                    maxNumConditions: 1,
                },
                sortable: true,
            },
            { field: "created", headerName: "Created", width: 280,
                filter: 'agDateColumnFilter',
                filterParams:dateFilterParametersInHeaderSuppressSorting,
                sortable: true,
                valueFormatter: dateValueFormatter
            },
            { field: "collected", headerName: "Collected", width: 280,
                filter: 'agDateColumnFilter',
                filterParams:dateFilterParametersInHeaderSuppressSorting,
                sortable: true,
                valueFormatter: dateValueFormatter
            },
        ]
        return {
            detailGridOptions: {
                getRowId: (params) => {
                    return params.data.incidentId;
                },
                columnDefs: detailGridColumnDefs,
                defaultColDef: {
                    resizable: true,
                    filterParams: null,
                    headerClass:"border-0 border-b-0",
                    cellClass:"outline:none",
                    floatingFilter: true,
                    cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                },
                suppressExcelExport: true,
                suppressCsvExport: true,
                enableCellTextSelection : true,
                components: {agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor},
                onCellEditingStopped: incidentPageCellEditingStopped,
                getContextMenuItems: this.getContextMenuItems
            },
            getDetailRowData: async (detailRowDataParams) => {
                let masterRowData = detailRowDataParams.node.data
                let detailRowData = []
                try{
                    detailRowData = await getDetailRowsForIncidentTimeSeriesReactive(masterRowData.agentId, masterRowData.zenGroupId, masterRowData.dateCreatedRounded,
                        masterRowData.pathEncoded, masterRowData.programArgumentsEncoded, masterRowData.parentPathEncoded, masterRowData.parentProgramArgumentsEncoded)
                } catch (e) {

                }
                if(!agentNameOfMaster){
                    //Need to call to api to get agent name if agentNameOfMaster does not have a value so we don't show blank cell in detail grid. This is edge case and only happens if they expand row before the
                    // agent name api call returns, then the detail grid doesn't get the update for agent name col.
                    try{
                        let agentNameObjects = await getAgentFriendlyNamesReactive([agentIdOfMaster])
                        if(agentNameObjects && agentNameObjects.length>0) {
                            if (agentNameObjects[0].name) {
                                agentNameOfMaster = agentNameObjects[0].name
                            }
                            /*
                            if (agentNameObjects[0].machineName) {
                                machineNameForLocationLinkOfMaster = agentNameObjects[0].machineName
                            }

                             */
                        }
                    } catch (e) {}
                }
                detailRowDataParams.successCallback(detailRowData)
            },
        }

    }

    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="incidentGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, MultiFilterModule, MasterDetailModule, ClientSideRowModelModule]} //ClientSideRowModelModule is also needed for detail grid
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.initialGridColumnDefs}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        detailRowHeight={this.props.detailRowHeight}
                        keepDetailRows={true} //keeps detail rows
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        masterDetail={true}
                        detailCellRendererParams={this.detailCellRendererParams}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={incidentPageCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        tooltipShowDelay={0}
                        tooltipMouseTrack={true }
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges) {
                                onFilterChangedHelper(params, 'incidentsGridFilterState', updateIncidentsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}

class DetailsGrid extends Component {
    constructor(props, onClickRow, filterVals, headers, data) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.autoSizeAllColumns()
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
    }

    render() {
        return (
            <div className="mt-2">
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            floatingFilter: false,
                            headerClass: "border-0 border-b-0",
                            cellClass: "outline:none",
                            enableCellChangeFlash: true,
                            autoHeight: false,
                            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                        }}
                        columnDefs={this.props.headers}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule, MenuModule]}
                        suppressMultiSort={true}
                        domLayout="autoHeight"
                        headerHeight={50}
                        enableBrowserTooltips={true}
                        rowData={this.props.data}
                        onFirstDataRendered={this.onFirstDataRendered}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    />
                </div>
            </div>
        );
    }
}
export function base64ToArrayBuffer(base64) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export function getDateStringForAgGridFilter(millis, secondsToUse = null){
    try{
        //Need to format the date filter string for ag grid. Have to also convert the date to user's saved timezone setting
        let timezone = decryptAndGetSessionVariable("timezone")
        if(timezone === null || timezone === undefined){
            timezone = "Europe/London" //default to london gmt time if timezone is null
        }
        let date = new Date(new Date(millis).toLocaleString('en-US', {timeZone: timezone}))
        let year = date.getFullYear()
        let month = date.getMonth() + 1 //month starts at 0
        let day = date.getDate()
        let dateString = `${year}-${month}-${day}`
        let hour = date.getHours()
        let mins = date.getMinutes()
        let seconds = 0 //dateCreatedRounded is set to 0 upon insertion, so we should set it to 0 here so the seconds don't go over
        //secondsToUse was implemented if it is desired to directly set the seconds to use for the filter, instead of it always being 0. The fullDataIncidentFilterCriteria auto filter inRange end date will use 59s instead of 0
        if(secondsToUse !== null && secondsToUse !== undefined){
            seconds = secondsToUse
        }
        let timeString = `${hour}:${mins}:${seconds}`

        return `${dateString} ${timeString}`
    } catch (e) {
        return null
    }
}

function getAgGridDateRangeFilterForIncidentDateCreatedRoundedInterval(millis){
    //The same as getDateStringForAgGridFilter, except this will return both the start and end date for the extortionResponseFullFilterCriteriaEncoded URL param auto filter.
    //It will take the start date and add 9 mins 59s and return both dates as strings formatted for ag grid.
    try{
        //Need to format the date filter string for ag grid. Have to also convert the date to user's saved timezone setting
        let timezone = decryptAndGetSessionVariable("timezone")
        if(timezone === null || timezone === undefined){
            timezone = "Europe/London" //default to london gmt time if timezone is null
        }
        //Format start date filter
        let dateCreatedRounded = new Date(new Date(millis).toLocaleString('en-US', {timeZone: timezone}))
        let startYear = dateCreatedRounded.getFullYear()
        let startMonth = dateCreatedRounded.getMonth() + 1 //month starts at 0
        let startDay = dateCreatedRounded.getDate()
        let startDateString = `${startYear}-${startMonth}-${startDay}`
        let startHour = dateCreatedRounded.getHours()
        let startMins = dateCreatedRounded.getMinutes()
        let startSeconds = 0 //dateCreatedRounded is set to 0 upon insertion, so we should set it to 0 here so the seconds don't go over
        let startTimeString = `${startHour}:${startMins}:${startSeconds}`
        let startTimeFilterString = `${startDateString} ${startTimeString}`

        //Format end date filter which is just adding 9 mins 59s
        dateCreatedRounded.setMinutes(dateCreatedRounded.getMinutes() + 10)
        dateCreatedRounded.setSeconds(dateCreatedRounded.getSeconds() - 1)

        let endYear = dateCreatedRounded.getFullYear()
        let endMonth = dateCreatedRounded.getMonth() + 1 //month starts at 0
        let endDay = dateCreatedRounded.getDate()
        let endDateString = `${endYear}-${endMonth}-${endDay}`
        let endHour = dateCreatedRounded.getHours()
        let endMins = dateCreatedRounded.getMinutes()
        let endSeconds = 59 //always use 59 seconds for end date filter
        let endTimeString = `${endHour}:${endMins}:${endSeconds}`
        let endTimeFilterString = `${endDateString} ${endTimeString}`

        return {
            "startDate": startTimeFilterString,
            "endDate": endTimeFilterString
        }

    } catch (e) {
        return null
    }
}