import axios from "../../utils/axiosInstance";

export const agentNamesListReactive = async () => {
    const response = await axios.post("/agentNamesListReactive", {
    });
    return response.data;
};

export const getAgentVersionsCountPerGroupReactive = async (agentChartLastActiveDays) => {
    const response = await axios.post("/agentVersionsCountPerGroupReactive", {
        agentChartLastActiveDays: agentChartLastActiveDays
    });
    return response.data;
};

export const getLatestAgentVersionReactive = async () => {
    const response = await axios.post("/latestAgentVersionReactive", {
    });
    return response.data;
};
export const getValidatedStagedUpdatesReactive = async (agentId) => {
    const response = await axios.post("/getValidatedStagedUpdatesReactive", {
        agentId
    })
    return response.data.stagedUpdate
}

export const getZenGroupFriendlyNamesReactive = async (zenGroupIds) => {
    const response = await axios.post("/getZenGroupFriendlyNamesReactive",{
        zenGroupIds
    })
    return response.data;
}

export const findByAgentIdListReactive = async (agentIds) => {
    const response = await axios.post("/findByAgentIdListReactive",{
        agentIds
    })
    return response.data
}

export const getZenGroupCodesForSiteReactive = async (zenGroup) => {
    const response = await axios.post("/getZenGroupCodesForSiteReactive", {
        zenGroup,
    });
    return response.data.codes;
};

export const singleChangeAgentGroupReactive = async (zenGroup, agent) => {
    const response = await axios.post("/changeAgentZenGroupReactive", {
        newZenGroupId: zenGroup,
        agentId: agent, //only one id for now
    });
    return response;
};

export const uninstallAgentReactive = async (agentId) => {
    return await axios.post("/uninstallAgentReactive", {
        agentId: agentId
    });
};

export const singleSetAgentAutoUpgradeReactive = async (agentId, autoUpgradeOn) => {
    return await axios.post("/singleSetAgentAutoUpgradeReactive", {
        agentId: agentId,
        autoUpgradeOn: autoUpgradeOn
    });
};

//where do we axios.post this?
export const createEncryptionCheckTaskReactive = async (agentId) => {
    const response = await axios.post("/createEncryptionCheckTaskReactive", {
        agentId: agentId
    });
    return response;
};
export const changeAgentNameReactive = async (newName, groupId, agentId) => {
    const response = await axios.post("/changeAgentNameReactive", {
        "newName": newName,
        "zenGroupId": groupId,
        "id": agentId
    });
    return response;
};

export const cancelUninstallAgentReactive = async (agentId) => {
    return await axios.post("/cancelUninstallAgentReactive", {
        agentId: agentId
    });
};
export const generateInstallerReactive = async (groupId, type, proxyUrl, proxyUsername) => {
    const response = await axios.post("/generateInstallerReactive",{groupId: groupId, type: type, proxyUrl: proxyUrl, proxyUsername: proxyUsername}
    );
    return response.data;
};

export const singlePermissionCheckReactive = async (zenGroupId, permissionManagerName, permissionActionName) => {
    const response = await axios.post("/singlePermissionCheckReactive",{
        zenGroupId: zenGroupId,
        permissionManagerName: permissionManagerName,
        permissionActionName: permissionActionName
    });
    return response.data;
};
export const singleSetAgentVisibilityReactive = async (agentId, hiddenFromUI) => {
    return await axios.post("/singleSetAgentVisibilityReactive", {
        agentId: agentId,
        hiddenFromUI: hiddenFromUI
    });
};

export const getAgentsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getAgentsGridFilterStateReactive", {});
    return response.data;
};

export const updateAgentsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateAgentsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateAgentsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateAgentsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const assignAgentLicenseReactive = async (agentId) => {
    return await axios.post("/assignAgentLicenseReactive", {
        agentId: agentId
    });
};
export const bulkAssignAgentLicensesReactive = async (agentIds) => {
    return await axios.post("/bulkAssignAgentLicensesReactive", {
        agentIds: agentIds
    });
};
export const removeAgentManagedUpdateSettingsReactive = async (agentId) => {
    return await axios.post("/removeAgentManagedUpdateSettingsReactive", {
        agentId: agentId
    });
};
export const overrideGroupUpdatePolicyForAgentReactive = async (agentId) => {
    return await axios.post("/overrideGroupUpdatePolicyForAgentReactive", {
        agentId: agentId
    });
};
export const editAgentManagedUpdateAutoUpdateSettingReactive = async (agentId, newAutoUpdateSetting) => {
    return await axios.post("/editAgentManagedUpdateAutoUpdateSettingReactive", {
        agentId: agentId,
        newAutoUpdateSetting: newAutoUpdateSetting
    });
};
export const editAgentManagedUpdateLatestAgentVersionApprovedSettingReactive = async (agentId, newLatestAgentVersionApproved) => {
    return await axios.post("/editAgentManagedUpdateLatestAgentVersionApprovedSettingReactive", {
        agentId: agentId,
        newLatestAgentVersionApproved: newLatestAgentVersionApproved
    });
};

export const getAgentsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getAgentsGridColumnStateReactive", {});
    return response.data;
};

export const distinctAgentVersionsReactive = async () => {
    const response =  await axios.post("/distinctAgentVersionsReactive", {});
    return response.data;
};

export const updateAgentsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateAgentsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateAgentsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateAgentsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};
export const updateAgentsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateAgentsGridColumnModeReactive", {
        columnMode: columnMode
    })
}

export const updateAgentChartVisibilityReactive = async (visible) => {
    return await axios.post("/updateAgentChartVisibilityReactive", {
        visible: visible
    });
};
export const releaseAgentLicenseReactive = async (agentId) => {
    return await axios.post("/releaseAgentLicenseReactive", {
        agentId: agentId
    });
};
export const changeAgentAutoAssignLicenseInValidateReactive = async (agentId, doNotLookForLicense) => {
    return await axios.post("/changeAgentAutoAssignLicenseInValidateReactive", {
        agentId: agentId,
        doNotLookForLicense: doNotLookForLicense
    });
};