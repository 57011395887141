import axios from "../../utils/axiosInstance";


export const launchLinkedInCompanyScraperPhantomBusterReactive = async (linkedInCompanyURLs, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchLinkedInCompanyScraperPhantomBusterReactive", {
        linkedInCompanyURLs: linkedInCompanyURLs,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
export const launchSalesNavigatorEmployeeExportPhantomBusterReactive = async (linkedInCompanyURLs, sessionCookie, userAgentFromSessionCookie) => {
    return await axios.post("/launchSalesNavigatorEmployeeExportPhantomBusterReactive", {
        linkedInCompanyURLs: linkedInCompanyURLs,
        sessionCookie: sessionCookie,
        userAgentFromSessionCookie: userAgentFromSessionCookie,
    })
};
